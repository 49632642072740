import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularWeatherWidgetModule, WeatherApiName } from '../../node_modules/angular-weather-widget/dist';

import { NewsComponent } from './components/news/news.component';
import { FeedCardComponent } from './components/news/feed-card/feed-card.component';
import { FeedService } from './components/news/feed.service';
import { StripHtmlTagsPipe } from './components/news/pipe/strip-html-tags.pipe';
import { DecodeHtmlEntitiesPipe } from './components/news/pipe/decode-html-entities.pipe';
import { LimitTextLengthPipe } from './components/news/pipe/limit-text-length-pipe';
import { SpinnerComponent } from './components/news/spinner/spinner.component';

import { MatIconModule } from '@angular/material/icon';
import {
  MatButtonModule,
  MatCardModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSelectModule,
  MatFormFieldModule,
  MatIconRegistry,
  MatInputModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatToolbarModule
} from '@angular/material';

import { ToastrModule } from 'ngx-toastr';

import { OneControlRoutingModule } from './onecontrol/onecontrol-routing.module';

import { OpenHabService } from './services/open-hab.service';
import { OpenHabService as TestOpenHabService } from './services/test/open-hab.service';
import { OpenHabCacheService } from './services/open-hab-cache.service';
import { OpenHabCacheService as TestOpenHabCacheService } from './services/test/open-hab-cache.service';
import { ConnectionNotifierService } from './services/connection-notifier.service';
import { ThingService } from './services/thing.service';
import { LightsService } from './services/lights.service';
import { LocationService } from './services/location.service';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { HvacComponent } from './components/hvac/hvac.component';
import { LocksComponent } from './components/locks/locks.component';
import { PowerComponent } from './components/power/power.component';
import { FansComponent } from './components/fans/fans.component';
import { ThingComponent } from './thing/thing.component';
import { ChannelComponent } from './channel/channel.component';
import { DialComponent } from './ui/dial/dial.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { LoggingInterceptor } from './services/logging-interceptor';
import { MainNavComponent } from './ui/main-nav/main-nav.component';
import { CurrentTempsComponent } from './ui/current-temps/current-temps.component';
import { AppShellComponent } from './app-shell/app-shell.component';
import { LightsComponent } from './components/lights/lights.component';
import { LightComponent } from './components/lights/light-list-item/light.component';
import { LightChannelComponent } from './components/lights/light-channel/light-channel.component';
import { environment } from '../environments/environment';
import { UtilityService } from './services/utility.service';
import { WeatherComponent } from './components/weather/weather.component';
import { DialogComponent } from './ui/dialog/dialog.component';
import { ConnectionModalComponent } from './ui/connection-modal/connection-modal.component';
import { TanksComponent } from './components/tanks/tanks.component';
import { TankComponent } from './components/tanks/tank-list-item/tank.component';
import { TanksWidgetComponent } from './components/dashboard/widgets/tanks/tanks.widget.component';
import { TimeComponent } from './components/time/time.component';
import { LocationComponent } from './components/location/location.component';
import { ColorPickerComponent } from './ui/colorpicker/colorpicker.component';

import { ZoomComponent } from './components/zoom/zoom.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LightSwitchComponent } from './ui/light-switch/light-switch.component';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@NgModule({
  declarations: [
    AppComponent,
    NewsComponent,
    DashboardComponent,
    LightsComponent,
    HvacComponent,
    LocksComponent,
    PowerComponent,
    FansComponent,
    TanksComponent,
    PageNotFoundComponent,
    ThingComponent,
    ChannelComponent,
    DialComponent,
    PreferencesComponent,
    MainNavComponent,
    CurrentTempsComponent,
    AppShellComponent,
    LightComponent,
    LightChannelComponent,
    WeatherComponent,
    DialogComponent,
    ConnectionModalComponent,
    TanksWidgetComponent,
    TankComponent,
    TimeComponent,
    LocationComponent,
    ZoomComponent,
    ColorPickerComponent,
    LightSwitchComponent,
    FeedCardComponent,
    StripHtmlTagsPipe,
    DecodeHtmlEntitiesPipe,
    LimitTextLengthPipe,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    OneControlRoutingModule,
    HttpClientModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatIconModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    AngularWeatherWidgetModule.forRoot({
      key: environment.weatherApiKey,
      name: WeatherApiName.OPEN_WEATHER_MAP,
      baseUrl: 'https://api.openweathermap.org/data/2.5'
    }),
    ReactiveFormsModule,
    Angulartics2Module.forRoot([ Angulartics2GoogleTagManager ])
  ],
  entryComponents: [
    DialogComponent, ConnectionModalComponent
  ],
  providers: [
    FeedService,
    MatIconRegistry,
    {
      provide: OpenHabService,
      useClass:  environment.production ? OpenHabService : TestOpenHabService
    },
    {
      provide: OpenHabCacheService,
      useClass:  environment.production ? OpenHabCacheService : TestOpenHabCacheService
    },
    ThingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectionNotifierService,
      multi: true
    },
    LightsService,
    UtilityService,
    LocationService
  ],
  bootstrap: [AppShellComponent]
})
export class AppModule { }
