import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Item } from '../models/item';
import { Thing } from '../models/thing';
import { Channel } from '../models/channel';
import { OpenHabCacheService } from './open-hab-cache.service';

/**
 * The service for getting location information
 */
@Injectable()
export class LocationService {
  private static positionIntervalId: number;
  private static observers: any[] = [];

  private static oldPosition: Position;

  private static readonly TOLERANCE_METERS_PER_SECOND: number = 1;

  private static locationItem: Item;
  private static accuracyItem: Item;
  private static speedItem: Item;
  private static courseItem: Item;
  private static position: Position;

  constructor(private http: HttpClient, private rest: OpenHabCacheService) {

    this.rest.getThings().then((things: Thing[]) =>
    {
      const locationThing: Thing = things.find((thing) => thing.thingTypeUID === 'idsmyrv:location');
      if (!locationThing)
      {
        return;
      }
      const locationChannel: Channel = locationThing.channels.find((channel) => channel.id === 'location');
      const accuracyChannel: Channel = locationThing.channels.find((channel) => channel.id === 'accuracy');
      const speedChannel: Channel = locationThing.channels.find((channel) => channel.id === 'speed');
      const courseChannel: Channel = locationThing.channels.find((channel) => channel.id === 'course');
      if (locationChannel)
      {
        this.rest.getItem(locationChannel.linkedItems[0]).then((item: Item) =>
        {
          LocationService.locationItem = item;
        });
      }
      if (accuracyChannel)
      {
        this.rest.getItem(accuracyChannel.linkedItems[0]).then((item: Item) =>
        {
          LocationService.accuracyItem = item;
        });
      }
      if (speedChannel)
      {
        this.rest.getItem(speedChannel.linkedItems[0]).then((item: Item) =>
        {
          LocationService.speedItem = item;
        });
      }
      if (courseChannel)
      {
        this.rest.getItem(courseChannel.linkedItems[0]).then((item: Item) =>
        {
          LocationService.courseItem = item;
        });
      }
    });
  }

  getLocationPositionFromBrowser(): Promise<any> {
    console.log('Trying to get position from the browser');
    const prom = new Promise((resolve, reject) => {
      if (LocationService.position) {
        console.log('Using cached browser position');
        resolve(LocationService.position);
      } else {
        console.log('Retrieving position from the browser');
        navigator.geolocation.getCurrentPosition((position: Position) => {
          console.log('Was able to retrieve position from browser');
          LocationService.position = position;
          resolve(position);
        }, (error) => {
          console.log('Was not able to retrieve position from browser');
          reject(error);
        });
      }
    });

    return prom;
  }

  /**
   * Gets information about the location
   * based on the geolocation position information.
   * @param position The location return by the geolocation API
   */
  getLocationInfoFromPosition(position: Position): Observable<Object>
  {
    const url: string = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + position.coords.longitude + ',' + position.coords.latitude + '.json?access_token=' + environment.mapBoxApiKey;
    return this.http.get(url);
  }

  /**
   * Gets information about the place you are at.
   * i.e. business name, city name, park, etc.
   * @param location The location returned by the geolocation API
   */
  getPlaceInfoFromPosition(position: Position): Observable<Object> {
    const url: string = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + position.coords.longitude + ',' + position.coords.latitude + '.json?types=poi&access_token=' + environment.mapBoxApiKey;
    return this.http.get(url);
  }

  /**
   * Gets image for the place you are at.
   * @param place The place returned by the place service
   */
  getImageForPlace(city: string, state: string): Observable<string> {
    const locationName: string = (city + ', ' + state);
    const wikiGetArticleUrl: string = 'https://en.wikipedia.org/w/api.php?action=query' +
      '&titles=' + locationName +
      '&prop=pageimages&format=json&origin=*';
    return Observable.create((observer) => { 
      this.http.get(wikiGetArticleUrl).subscribe((wikiResult: any) => {
        const hasPages: boolean = wikiResult && wikiResult.query && wikiResult.query.pages;
        if (!hasPages)
        {
          observer.next('');
          return;
        }
        const pageKeys: string[] = Object.keys(wikiResult.query.pages);
        const hasPageKey: boolean = Boolean(pageKeys.length);
        if (!hasPageKey)
        {
          observer.next('');
          return;
        }
        const firstPageKey: string = pageKeys[0];
        const imageName: string = wikiResult.query.pages[firstPageKey].pageimage;
        const wikiGetImageUrl: string = 'https://en.wikipedia.org/w/api.php?action=query' +
          '&titles=File:' + imageName +
          '&prop=imageinfo&iiprop=url&format=json&origin=*';
        this.http.get(wikiGetImageUrl).subscribe((wikiImageResults: any) =>
        {
          const hasPages: boolean = wikiImageResults && wikiImageResults.query && wikiImageResults.query.pages;
          if (!hasPages)
          {
            observer.next('');
            return;
          }
          const pageKeys: string[] = Object.keys(wikiImageResults.query.pages);
          const hasPageKey: boolean = Boolean(pageKeys.length);
          if (!hasPageKey)
          {
            observer.next('');
            return;
          }
          const firstPageKey: string = pageKeys[0];
          const imageInfo: any[] = wikiImageResults.query.pages[firstPageKey].imageinfo;
          const hasImageInfo: boolean = Boolean(imageInfo && imageInfo.length);
          if(!hasImageInfo){ observer.next(''); return; }
          const firstImageInfo: any = imageInfo[0];
          const fullImageUrl: string = (firstImageInfo.url || '');
          observer.next(fullImageUrl);
        });
      });
    });
  }

  /**
   * Returns an observable for the position information.
   * Polls every minute for an updated position, and updates
   * immediately when a new observer subscribes.
   */
  getPositionObservable() : Observable<Position> {
    // window callbacks sometime have issues with the
    // 'this' keyword, so we assign to self here 
    const self: LocationService = this;
    // create observable for location updates
    return Observable.create((observer)=> {
      // add to list of observers to be notified when
      // location is returned
      LocationService.observers.push(observer);

      // get location immediately since we have a new observer
      this.getPositionAndNotifyObservers();

      // if already polling, just return
      if(LocationService.positionIntervalId){ return; }

      // poll every second for updated location
      // need to poll at this speed in case they are moving
      LocationService.positionIntervalId = window.setInterval(() => {
        self.getPositionAndNotifyObservers();
      }, 1000);

      // unsub method
      return () => {
        // filter out this observer from list of observers
        LocationService.observers = LocationService.observers.filter((obs) => { return obs !== observer; });
        // if no observers left, clear the interval polling for location
        if(!LocationService.observers.length){
          window.clearInterval(LocationService.positionIntervalId);
        }
      };
    });
  }

  /**
   * Calls the geolocation API and notifies all observers of the 
   * position changes.
   */
  getPositionAndNotifyObservers() {
    if (!LocationService.locationItem)
    {
      console.log('No location thing found');
      return;
    }
    // use location items when available
    let latLng: string[] = LocationService.locationItem.state.toString().split(',');
    let accuracy: number = LocationService.accuracyItem ? Number(LocationService.accuracyItem.state.toString()) : 0;
    let speed: number = LocationService.speedItem ? Number(LocationService.speedItem.state.toString()) : 0;
    let course: number = LocationService.courseItem ? Number(LocationService.courseItem.state.toString()) : 0;
    let position: Position = <Position>{
      coords: <Coordinates>{
        latitude: Number(latLng[0]),
        longitude: Number(latLng[1]),
        accuracy: accuracy,
        speed: speed,
        heading: course
      },
      timestamp: Date.now()
    };
    LocationService.oldPosition = position;
    // notify all added observers
    LocationService.observers.forEach((obs) => {
      obs.next(position);
    });
  }

  //https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates-shows-wrong
  calculateDistance(lat1: number, long1: number, lat2: number, long2: number) : number
  {    
    //radians
    lat1 = (lat1 * 2.0 * Math.PI) / 60.0 / 360.0;      
    long1 = (long1 * 2.0 * Math.PI) / 60.0 / 360.0;    
    lat2 = (lat2 * 2.0 * Math.PI) / 60.0 / 360.0;   
    long2 = (long2 * 2.0 * Math.PI) / 60.0 / 360.0;       


    // use to different earth axis length    
    let a: number = 6378137.0;        // Earth Major Axis (WGS84)    
    let b: number = 6356752.3142;     // Minor Axis    
    let f: number = (a-b) / a;        // "Flattening"    
    let e: number = 2.0*f - f*f;      // "Eccentricity"      

    let beta: number = (a / Math.sqrt( 1.0 - e * Math.sin( lat1 ) * Math.sin( lat1 )));    
    let cos: number = Math.cos( lat1 );    
    let x: number = beta * cos * Math.cos( long1 );    
    let y: number = beta * cos * Math.sin( long1 );    
    let z: number = beta * ( 1 - e ) * Math.sin( lat1 );      

    beta = ( a / Math.sqrt( 1.0 -  e * Math.sin( lat2 ) * Math.sin( lat2 )));    
    cos = Math.cos( lat2 );   
    x -= (beta * cos * Math.cos( long2 ));    
    y -= (beta * cos * Math.sin( long2 ));    
    z -= (beta * (1 - e) * Math.sin( lat2 ));       

    return (Math.sqrt( (x*x) + (y*y) + (z*z) )/1000);  
  }

  // https://stackoverflow.com/questions/11415106/issue-with-calcuating-compass-bearing-between-two-gps-coordinates
  /**
   * Calculate the bearing between two positions as a value from 0-360
   *
   * @param lat1 - The latitude of the first position
   * @param lng1 - The longitude of the first position
   * @param lat2 - The latitude of the second position
   * @param lng2 - The longitude of the second position
   *
   * @return int - The bearing between 0 and 360
   */
  calculateBearing (lat1: number, lng1: number, lat2: number, lng2: number) : number {
    let dLon: number = this.toRad(lng2-lng1);
    let y: number = Math.sin(dLon) * Math.cos(this.toRad(lat2));
    let x: number = Math.cos(this.toRad(lat1))*Math.sin(this.toRad(lat2)) - Math.sin(this.toRad(lat1))*Math.cos(this.toRad(lat2))*Math.cos(dLon);
    let brng: number = this.toDeg(Math.atan2(y, x));
    return (brng + 360) % 360;
  }

 /**
   * Since not all browsers implement this we have our own utility that will
   * convert from degrees into radians
   *
   * @param deg - The degrees to be converted into radians
   * @return radians
   */
  toRad (deg) : number {
       return deg * Math.PI / 180;
  }

  /**
   * Since not all browsers implement this we have our own utility that will
   * convert from radians into degrees
   *
   * @param rad - The radians to be converted into degrees
   * @return degrees
   */
  toDeg (rad) : number {
      return rad * 180 / Math.PI;
  }

  addSpeedAndHeading(oldPosition: Position, newPosition: Position) : Position {
    let positionCopy: Position = <Position>{
      coords: <Coordinates>{
        latitude: newPosition.coords.latitude,
        longitude: newPosition.coords.longitude,
        accuracy: newPosition.coords.accuracy,
        altitude: newPosition.coords.altitude,
        altitudeAccuracy: newPosition.coords.altitudeAccuracy
      },
      timestamp: newPosition.timestamp
    }; 

    if(!oldPosition){ return positionCopy; }

    if(newPosition.coords.speed && newPosition.coords.heading){ return positionCopy; }

    let distanceMeters: number = this.calculateDistance(
        oldPosition.coords.latitude, 
        oldPosition.coords.longitude,
        newPosition.coords.latitude,
        newPosition.coords.longitude
    );

    // no change in position, just return new position
    if(!distanceMeters){ return positionCopy; }

    let timeChange: number = newPosition.timestamp - oldPosition.timestamp;
    let timeChangeSeconds: number = timeChange / 1000;

    // slower than walking speed, don't add
    let speed: number = distanceMeters / timeChangeSeconds;

    if(speed < LocationService.TOLERANCE_METERS_PER_SECOND) { return positionCopy; }

    let heading = this.calculateBearing(
      oldPosition.coords.latitude, 
      oldPosition.coords.longitude,
      newPosition.coords.latitude,
      newPosition.coords.longitude
    );

    return <Position>{
      coords: <Coordinates>{
        latitude: newPosition.coords.latitude,
        longitude: newPosition.coords.longitude,
        accuracy: newPosition.coords.accuracy,
        speed: speed,
        heading: heading,
        altitude: newPosition.coords.altitude,
        altitudeAccuracy: newPosition.coords.altitudeAccuracy
      },
      timestamp: newPosition.timestamp
    };
  }

}
