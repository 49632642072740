import { ThingIcons } from './thing-icons.enum';
import { Section } from './section';
import { ThingTypes } from './openhab-things';

export const Sections: Array<Section> = [
    {slug: 'Dashboard', label: 'Dashboard', path: '#/dashboard', icon: 'fa fa-fw fa-dashboard'}
    , {slug: 'Lights', label: 'Lights', path: '#/lights', icon: 'fa fa-fw ' + ThingIcons['LIGHT'], thing_type: ThingTypes['light-thing']}
    , {slug: 'Hvac', label: 'HVAC', path: '#/hvac', icon: 'fa fa-fw ' + ThingIcons['HVAC_CONTROL'], thing_type: ThingTypes['hvac-thing']}
    , {slug: 'Fans', label: 'Fans', path: '#/fans', icon: 'fa fa-fw ' + ThingIcons['FAN'], thing_type: ThingTypes['fan-thing']}
    , {slug: 'Power', label: 'Power', path: '#/power', icon: 'fa fa-fw ' + ThingIcons['GENERATOR'], thing_type: ThingTypes['power-thing']}
    , {slug: 'Tanks', label: 'Tanks', path: '#/tanks', icon: 'fa fa-fw ' + ThingIcons['TANK'], thing_type: ThingTypes['tank-thing']}
];
