import { LightRate } from './light-rate';

export class LightRates {
    static 'SLOW': LightRate = {
        rateOn: 1600,
        rateOff: 801,
        rateDefault: 1600
    };
    static 'MEDIUM': LightRate = {
        rateOn: 800,
        rateOff: 101,
        rateDefault: 800
    };
    static 'FAST': LightRate = {
        rateOn: 100,
        rateOff: 1,
        rateDefault: 100
    };
}


export class RgbLightRates {
    static 'SLOW': LightRate = {
        rateOn: 255,
        rateOff: 142,
        rateDefault: 255
    };
    static 'MEDIUM': LightRate = {
        rateOn: 141,
        rateOff: 17,
        rateDefault: 141
    };
    static 'FAST': LightRate = {
        rateOn: 16,
        rateOff: 1,
        rateDefault: 16
    };
}
