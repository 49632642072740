// IDS.Core.IDS_CAN.Descriptors.cs
// Copyright (C) 2018 Innovative Design Solutions
// All rights reserved
//
// THIS CODE WAS GENERATED FROM CoreDescGen.exe AND IT SHOULDN'T BE HAND EDITED.
//
import { FunctionConfig } from './function-config';


export namespace IdsCan {
  export function decimalToHex(decimal: number|string): string {
      return ((decimal as any) + Math.pow(16, 4)).toString(16).slice(-4).toUpperCase() + 'h';
  }

  export class DeviceClasses {
    static AWNING: string = 'AWNING';
    static BED_LIFT: string = 'BED_LIFT';
    static DOOR: string = 'DOOR';
    static ELECTRIC_WATER_HEATER: string = 'ELECTRIC_WATER_HEATER';
    static FAN: string = 'FAN';
    static GAS_WATER_HEATER: string = 'GAS_WATER_HEATER';
    static GENERATOR: string = 'GENERATOR';
    static HOUR_METER: string = 'HOUR_METER';
    static HVAC_CONTROL: string = 'HVAC_CONTROL';
    static IPDM: string = 'IPDM';
    static IR_REMOTE_CONTROL: string = 'IR_REMOTE_CONTROL';
    static LANDING_GEAR: string = 'LANDING_GEAR';
    static LEVELER: string = 'LEVELER';
    static LEVELER_2: string = 'LEVELER_2';
    static LIGHT: string = 'LIGHT';
    static LOCK: string = 'LOCK';
    static LP_TANK_VALVE: string = 'LP_TANK_VALVE';
    static MISCELLANEOUS: string = 'MISCELLANEOUS';
    static NETWORK_BRIDGE: string = 'NETWORK_BRIDGE';
    static POWER: string = 'POWER';
    static PUMP: string = 'PUMP';
    static REAL_TIME_CLOCK: string = 'REAL_TIME_CLOCK';
    static SLIDE: string = 'SLIDE';
    static STABILIZER: string = 'STABILIZER';
    static TANK: string = 'TANK';
    static TEMPERATURE_SENSOR: string = 'TEMPERATURE_SENSOR';
    static TV_LIFT: string = 'TV_LIFT';
    static UNKNOWN: string = 'UNKNOWN';
    static VENT: string = 'VENT';
    static VENT_COVER: string = 'VENT_COVER';
    static WATER_TANK_HEATER: string = 'WATER_TANK_HEATER';
  }


  export class Sections implements DeviceClasses {
    static Tanks: Array<string> = [
      DeviceClasses.WATER_TANK_HEATER
      , DeviceClasses.ELECTRIC_WATER_HEATER
      , DeviceClasses.GAS_WATER_HEATER
      , DeviceClasses.PUMP
      , DeviceClasses.LP_TANK_VALVE
      , DeviceClasses.TANK
    ];

    static TankSwitches: Array<string> = [
      DeviceClasses.WATER_TANK_HEATER
      , DeviceClasses.ELECTRIC_WATER_HEATER
      , DeviceClasses.GAS_WATER_HEATER
      , DeviceClasses.PUMP
      , DeviceClasses.LP_TANK_VALVE
    ];
  }

  export interface Sections {
    Tanks: Array<string>;
    TankSwitches: Array<string>;
  }

  export class Functions implements DeviceClasses {

    // all possible FUNCTION_IDs (FUNCTION_NAME w/ Default Class)
  static '0000h': FunctionConfig = { 'fn': 'UNKNOWN', 'class' : DeviceClasses.UNKNOWN };
  static '0001h': FunctionConfig = { 'fn': 'DIAGNOSTIC_TOOL', 'class' : DeviceClasses.MISCELLANEOUS };
  static '0002h': FunctionConfig = { 'fn': 'MYRV_TABLET', 'class' : DeviceClasses.MISCELLANEOUS };
  static '0003h': FunctionConfig = { 'fn': 'GAS_WATER_HEATER', 'class' : DeviceClasses.GAS_WATER_HEATER };
  static '0004h': FunctionConfig = { 'fn': 'ELECTRIC_WATER_HEATER', 'class' : DeviceClasses.ELECTRIC_WATER_HEATER };
  static '0005h': FunctionConfig = { 'fn': 'WATER_PUMP', 'class' : DeviceClasses.PUMP };
  static '0006h': FunctionConfig = { 'fn': 'BATH_VENT', 'class' : DeviceClasses.VENT };
  static '0007h': FunctionConfig = { 'fn': 'LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0008h': FunctionConfig = { 'fn': 'FLOOD_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0009h': FunctionConfig = { 'fn': 'WORK_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Ah': FunctionConfig = { 'fn': 'FRONT_BEDROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Bh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_OVERHEAD_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Ch': FunctionConfig = { 'fn': 'FRONT_BEDROOM_VANITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Dh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Eh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_LOFT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '000Fh': FunctionConfig = { 'fn': 'REAR_BEDROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0010h': FunctionConfig = { 'fn': 'REAR_BEDROOM_OVERHEAD_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0011h': FunctionConfig = { 'fn': 'REAR_BEDROOM_VANITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0012h': FunctionConfig = { 'fn': 'REAR_BEDROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0013h': FunctionConfig = { 'fn': 'REAR_BEDROOM_LOFT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0014h': FunctionConfig = { 'fn': 'LOFT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0015h': FunctionConfig = { 'fn': 'FRONT_HALL_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0016h': FunctionConfig = { 'fn': 'REAR_HALL_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0017h': FunctionConfig = { 'fn': 'FRONT_BATHROOM_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0018h': FunctionConfig = { 'fn': 'FRONT_BATHROOM_VANITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0019h': FunctionConfig = { 'fn': 'FRONT_BATHROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Ah': FunctionConfig = { 'fn': 'FRONT_BATHROOM_SHOWER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Bh': FunctionConfig = { 'fn': 'FRONT_BATHROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Ch': FunctionConfig = { 'fn': 'REAR_BATHROOM_VANITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Dh': FunctionConfig = { 'fn': 'REAR_BATHROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Eh': FunctionConfig = { 'fn': 'REAR_BATHROOM_SHOWER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '001Fh': FunctionConfig = { 'fn': 'REAR_BATHROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0020h': FunctionConfig = { 'fn': 'KITCHEN_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0021h': FunctionConfig = { 'fn': 'KITCHEN_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0022h': FunctionConfig = { 'fn': 'KITCHEN_PENDANTS_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0023h': FunctionConfig = { 'fn': 'KITCHEN_RANGE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0024h': FunctionConfig = { 'fn': 'KITCHEN_COUNTER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0025h': FunctionConfig = { 'fn': 'KITCHEN_BAR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0026h': FunctionConfig = { 'fn': 'KITCHEN_ISLAND_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0027h': FunctionConfig = { 'fn': 'KITCHEN_CHANDELIER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0028h': FunctionConfig = { 'fn': 'KITCHEN_UNDER_CABINET_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0029h': FunctionConfig = { 'fn': 'LIVING_ROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Ah': FunctionConfig = { 'fn': 'LIVING_ROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Bh': FunctionConfig = { 'fn': 'LIVING_ROOM_PENDANTS_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Ch': FunctionConfig = { 'fn': 'LIVING_ROOM_BAR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Dh': FunctionConfig = { 'fn': 'GARAGE_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Eh': FunctionConfig = { 'fn': 'GARAGE_CABINET_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '002Fh': FunctionConfig = { 'fn': 'SECURITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0030h': FunctionConfig = { 'fn': 'PORCH_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0031h': FunctionConfig = { 'fn': 'AWNING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0032h': FunctionConfig = { 'fn': 'BATHROOM_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0033h': FunctionConfig = { 'fn': 'BATHROOM_VANITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0034h': FunctionConfig = { 'fn': 'BATHROOM_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0035h': FunctionConfig = { 'fn': 'BATHROOM_SHOWER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0036h': FunctionConfig = { 'fn': 'BATHROOM_SCONCE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0037h': FunctionConfig = { 'fn': 'HALL_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0038h': FunctionConfig = { 'fn': 'BUNK_ROOM_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0039h': FunctionConfig = { 'fn': 'BEDROOM_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Ah': FunctionConfig = { 'fn': 'LIVING_ROOM_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Bh': FunctionConfig = { 'fn': 'KITCHEN_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Ch': FunctionConfig = { 'fn': 'LOUNGE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Dh': FunctionConfig = { 'fn': 'CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Eh': FunctionConfig = { 'fn': 'ENTRY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '003Fh': FunctionConfig = { 'fn': 'BED_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0040h': FunctionConfig = { 'fn': 'BEDROOM_LAV_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0041h': FunctionConfig = { 'fn': 'SHOWER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0042h': FunctionConfig = { 'fn': 'GALLEY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0043h': FunctionConfig = { 'fn': 'FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '0044h': FunctionConfig = { 'fn': 'GREY_TANK', 'class' : DeviceClasses.TANK };
  static '0045h': FunctionConfig = { 'fn': 'BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '0046h': FunctionConfig = { 'fn': 'FUEL_TANK', 'class' : DeviceClasses.TANK };
  static '0047h': FunctionConfig = { 'fn': 'GENERATOR_FUEL_TANK', 'class' : DeviceClasses.TANK };
  static '0048h': FunctionConfig = { 'fn': 'AUXILLIARY_FUEL_TANK', 'class' : DeviceClasses.TANK };
  static '0049h': FunctionConfig = { 'fn': 'FRONT_BATH_GREY_TANK', 'class' : DeviceClasses.TANK };
  static '004Ah': FunctionConfig = { 'fn': 'FRONT_BATH_FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '004Bh': FunctionConfig = { 'fn': 'FRONT_BATH_BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '004Ch': FunctionConfig = { 'fn': 'REAR_BATH_GREY_TANK', 'class' : DeviceClasses.TANK };
  static '004Dh': FunctionConfig = { 'fn': 'REAR_BATH_FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '004Eh': FunctionConfig = { 'fn': 'REAR_BATH_BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '004Fh': FunctionConfig = { 'fn': 'MAIN_BATH_GREY_TANK', 'class' : DeviceClasses.TANK };
  static '0050h': FunctionConfig = { 'fn': 'MAIN_BATH_FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '0051h': FunctionConfig = { 'fn': 'MAIN_BATH_BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '0052h': FunctionConfig = { 'fn': 'GALLEY_GREY_TANK', 'class' : DeviceClasses.TANK };
  static '0053h': FunctionConfig = { 'fn': 'GALLEY_FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '0054h': FunctionConfig = { 'fn': 'GALLEY_BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '0055h': FunctionConfig = { 'fn': 'KITCHEN_GREY_TANK', 'class' : DeviceClasses.TANK };
  static '0056h': FunctionConfig = { 'fn': 'KITCHEN_FRESH_TANK', 'class' : DeviceClasses.TANK };
  static '0057h': FunctionConfig = { 'fn': 'KITCHEN_BLACK_TANK', 'class' : DeviceClasses.TANK };
  static '0058h': FunctionConfig = { 'fn': 'LANDING_GEAR', 'class' : DeviceClasses.LANDING_GEAR };
  static '0059h': FunctionConfig = { 'fn': 'FRONT_STABILIZER', 'class' : DeviceClasses.STABILIZER };
  static '005Ah': FunctionConfig = { 'fn': 'REAR_STABILIZER', 'class' : DeviceClasses.STABILIZER };
  static '005Bh': FunctionConfig = { 'fn': 'TV_LIFT', 'class' : DeviceClasses.TV_LIFT };
  static '005Ch': FunctionConfig = { 'fn': 'BED_LIFT', 'class' : DeviceClasses.BED_LIFT };
  static '005Dh': FunctionConfig = { 'fn': 'BATH_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '005Eh': FunctionConfig = { 'fn': 'DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '005Fh': FunctionConfig = { 'fn': 'GENERATOR', 'class' : DeviceClasses.GENERATOR };
  static '0060h': FunctionConfig = { 'fn': 'SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0061h': FunctionConfig = { 'fn': 'MAIN_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0062h': FunctionConfig = { 'fn': 'BEDROOM_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0063h': FunctionConfig = { 'fn': 'GALLEY_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0064h': FunctionConfig = { 'fn': 'KITCHEN_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0065h': FunctionConfig = { 'fn': 'CLOSET_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0066h': FunctionConfig = { 'fn': 'OPTIONAL_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0067h': FunctionConfig = { 'fn': 'DOOR_SIDE_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0068h': FunctionConfig = { 'fn': 'OFF_DOOR_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0069h': FunctionConfig = { 'fn': 'AWNING', 'class' : DeviceClasses.AWNING };
  static '006Ah': FunctionConfig = { 'fn': 'LEVEL_UP_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '006Bh': FunctionConfig = { 'fn': 'WATER_TANK_HEATER', 'class' : DeviceClasses.WATER_TANK_HEATER };
  static '006Ch': FunctionConfig = { 'fn': 'MYRV_TOUCHSCREEN', 'class' : DeviceClasses.MISCELLANEOUS };
  static '006Dh': FunctionConfig = { 'fn': 'LEVELER', 'class' : DeviceClasses.LEVELER };
  static '006Eh': FunctionConfig = { 'fn': 'VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '006Fh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0070h': FunctionConfig = { 'fn': 'BEDROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0071h': FunctionConfig = { 'fn': 'FRONT_BATHROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0072h': FunctionConfig = { 'fn': 'MAIN_BATHROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0073h': FunctionConfig = { 'fn': 'REAR_BATHROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0074h': FunctionConfig = { 'fn': 'KITCHEN_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0075h': FunctionConfig = { 'fn': 'LIVING_ROOM_VENT_COVER', 'class' : DeviceClasses.VENT_COVER };
  static '0076h': FunctionConfig = { 'fn': 'FOUR_LEG_TRUCK_CAMPLER_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '0077h': FunctionConfig = { 'fn': 'SIX_LEG_HALL_EFFECT_EJ_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '0078h': FunctionConfig = { 'fn': 'PATIO_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0079h': FunctionConfig = { 'fn': 'HUTCH_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Ah': FunctionConfig = { 'fn': 'SCARE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Bh': FunctionConfig = { 'fn': 'DINETTE_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Ch': FunctionConfig = { 'fn': 'BAR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Dh': FunctionConfig = { 'fn': 'OVERHEAD_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Eh': FunctionConfig = { 'fn': 'OVERHEAD_BAR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '007Fh': FunctionConfig = { 'fn': 'FOYER_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0080h': FunctionConfig = { 'fn': 'RAMP_DOOR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0081h': FunctionConfig = { 'fn': 'ENTERTAINMENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0082h': FunctionConfig = { 'fn': 'REAR_ENTRY_DOOR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0083h': FunctionConfig = { 'fn': 'CEILING_FAN_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0084h': FunctionConfig = { 'fn': 'OVERHEAD_FAN_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0085h': FunctionConfig = { 'fn': 'BUNK_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0086h': FunctionConfig = { 'fn': 'BED_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0087h': FunctionConfig = { 'fn': 'WARDROBE_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0088h': FunctionConfig = { 'fn': 'ENTERTAINMENT_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0089h': FunctionConfig = { 'fn': 'SOFA_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '008Ah': FunctionConfig = { 'fn': 'PATIO_AWNING', 'class' : DeviceClasses.AWNING };
  static '008Bh': FunctionConfig = { 'fn': 'REAR_AWNING', 'class' : DeviceClasses.AWNING };
  static '008Ch': FunctionConfig = { 'fn': 'SIDE_AWNING', 'class' : DeviceClasses.AWNING };
  static '008Dh': FunctionConfig = { 'fn': 'JACKS', 'class' : DeviceClasses.LEVELER };
  static '008Eh': FunctionConfig = { 'fn': 'LEVELER_2', 'class' : DeviceClasses.LEVELER };
  static '008Fh': FunctionConfig = { 'fn': 'EXTERIOR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0090h': FunctionConfig = { 'fn': 'LOWER_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0091h': FunctionConfig = { 'fn': 'UPPER_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0092h': FunctionConfig = { 'fn': 'DS_SECURITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0093h': FunctionConfig = { 'fn': 'ODS_SECURITY_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0094h': FunctionConfig = { 'fn': 'SLIDE_IN_SLIDE', 'class' : DeviceClasses.SLIDE };
  static '0095h': FunctionConfig = { 'fn': 'HITCH_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0096h': FunctionConfig = { 'fn': 'CLOCK', 'class' : DeviceClasses.REAL_TIME_CLOCK };
  static '0097h': FunctionConfig = { 'fn': 'TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '0098h': FunctionConfig = { 'fn': 'DVD', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '0099h': FunctionConfig = { 'fn': 'BLU_RAY', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Ah': FunctionConfig = { 'fn': 'VCR', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Bh': FunctionConfig = { 'fn': 'PVR', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Ch': FunctionConfig = { 'fn': 'CABLE', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Dh': FunctionConfig = { 'fn': 'SATELLITE', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Eh': FunctionConfig = { 'fn': 'AUDIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '009Fh': FunctionConfig = { 'fn': 'CD_PLAYER', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A0h': FunctionConfig = { 'fn': 'TUNER', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A1h': FunctionConfig = { 'fn': 'RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A2h': FunctionConfig = { 'fn': 'SPEAKERS', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A3h': FunctionConfig = { 'fn': 'GAME', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A4h': FunctionConfig = { 'fn': 'CLOCK_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A5h': FunctionConfig = { 'fn': 'AUX', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A6h': FunctionConfig = { 'fn': 'CLIMATE_ZONE', 'class' : DeviceClasses.HVAC_CONTROL };
  static '00A7h': FunctionConfig = { 'fn': 'FIREPLACE', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00A8h': FunctionConfig = { 'fn': 'THERMOSTAT', 'class' : DeviceClasses.HVAC_CONTROL };
  static '00A9h': FunctionConfig = { 'fn': 'FRONT_CAP_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00AAh': FunctionConfig = { 'fn': 'STEP_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00ABh': FunctionConfig = { 'fn': 'DS_FLOOD_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00ACh': FunctionConfig = { 'fn': 'INTERIOR_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00ADh': FunctionConfig = { 'fn': 'FRESH_TANK_HEATER', 'class' : DeviceClasses.WATER_TANK_HEATER };
  static '00AEh': FunctionConfig = { 'fn': 'GREY_TANK_HEATER', 'class' : DeviceClasses.WATER_TANK_HEATER };
  static '00AFh': FunctionConfig = { 'fn': 'BLACK_TANK_HEATER', 'class' : DeviceClasses.WATER_TANK_HEATER };
  static '00B0h': FunctionConfig = { 'fn': 'LP_TANK', 'class' : DeviceClasses.LP_TANK_VALVE };
  static '00B1h': FunctionConfig = { 'fn': 'STALL_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B2h': FunctionConfig = { 'fn': 'MAIN_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B3h': FunctionConfig = { 'fn': 'BATH_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B4h': FunctionConfig = { 'fn': 'BUNK_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B5h': FunctionConfig = { 'fn': 'BED_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B6h': FunctionConfig = { 'fn': 'CABINET_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00B7h': FunctionConfig = { 'fn': 'NETWORK_BRIDGE', 'class' : DeviceClasses.NETWORK_BRIDGE };
  static '00B8h': FunctionConfig = { 'fn': 'ETHERNET_BRIDGE', 'class' : DeviceClasses.NETWORK_BRIDGE };
  static '00B9h': FunctionConfig = { 'fn': 'WIFI_BRIDGE', 'class' : DeviceClasses.NETWORK_BRIDGE };
  static '00BAh': FunctionConfig = { 'fn': 'IN_TRANSIT_POWER_DISCONNECT', 'class' : 'In Transit Power Disconnect' };
  static '00BBh': FunctionConfig = { 'fn': 'LEVEL_UP_UNITY', 'class' : DeviceClasses.LEVELER };
  static '00BCh': FunctionConfig = { 'fn': 'TT_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '00BDh': FunctionConfig = { 'fn': 'TRAVEL_TRAILER_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '00BEh': FunctionConfig = { 'fn': 'FIFTH_WHEEL_LEVELER', 'class' : DeviceClasses.LEVELER };
  static '00BFh': FunctionConfig = { 'fn': 'FUEL_PUMP', 'class' : DeviceClasses.PUMP };
  static '00C0h': FunctionConfig = { 'fn': 'MAIN_CLIMATE_ZONE', 'class' : DeviceClasses.HVAC_CONTROL };
  static '00C1h': FunctionConfig = { 'fn': 'BEDROOM_CLIMATE_ZONE', 'class' : DeviceClasses.HVAC_CONTROL };
  static '00C2h': FunctionConfig = { 'fn': 'GARAGE_CLIMATE_ZONE', 'class' : DeviceClasses.HVAC_CONTROL };
  static '00C3h': FunctionConfig = { 'fn': 'COMPARTMENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00C4h': FunctionConfig = { 'fn': 'TRUNK_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00C5h': FunctionConfig = { 'fn': 'BAR_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00C6h': FunctionConfig = { 'fn': 'BATHROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00C7h': FunctionConfig = { 'fn': 'BEDROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00C8h': FunctionConfig = { 'fn': 'BUNK_ROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00C9h': FunctionConfig = { 'fn': 'EXTERIOR_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CAh': FunctionConfig = { 'fn': 'FRONT_BATHROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CBh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CCh': FunctionConfig = { 'fn': 'GARAGE_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CDh': FunctionConfig = { 'fn': 'KITCHEN_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CEh': FunctionConfig = { 'fn': 'LIVING_ROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00CFh': FunctionConfig = { 'fn': 'LOFT_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D0h': FunctionConfig = { 'fn': 'LOUNGE_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D1h': FunctionConfig = { 'fn': 'MAIN_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D2h': FunctionConfig = { 'fn': 'PATIO_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D3h': FunctionConfig = { 'fn': 'REAR_BATHROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D4h': FunctionConfig = { 'fn': 'REAR_BEDROOM_TV', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00D5h': FunctionConfig = { 'fn': 'BATHROOM_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00D6h': FunctionConfig = { 'fn': 'BEDROOM_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00D7h': FunctionConfig = { 'fn': 'FRONT_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00D8h': FunctionConfig = { 'fn': 'GARAGE_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00D9h': FunctionConfig = { 'fn': 'MAIN_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00DAh': FunctionConfig = { 'fn': 'PATIO_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00DBh': FunctionConfig = { 'fn': 'REAR_DOOR_LOCK', 'class' : DeviceClasses.LOCK };
  static '00DCh': FunctionConfig = { 'fn': 'ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00DDh': FunctionConfig = { 'fn': 'BATHROOM_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00DEh': FunctionConfig = { 'fn': 'BEDROOM_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00DFh': FunctionConfig = { 'fn': 'FRONT_BEDROOM_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00E0h': FunctionConfig = { 'fn': 'GARAGE_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00E1h': FunctionConfig = { 'fn': 'KITCHEN_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00E2h': FunctionConfig = { 'fn': 'PATIO_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00E3h': FunctionConfig = { 'fn': 'REAR_BEDROOM_ACCENT_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '00E4h': FunctionConfig = { 'fn': 'BEDROOM_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00E5h': FunctionConfig = { 'fn': 'BUNK_ROOM_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00E6h': FunctionConfig = { 'fn': 'EXTERIOR_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00E7h': FunctionConfig = { 'fn': 'FRONT_BEDROOM_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00E8h': FunctionConfig = { 'fn': 'GARAGE_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00E9h': FunctionConfig = { 'fn': 'KITCHEN_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00EAh': FunctionConfig = { 'fn': 'LIVING_ROOM_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00EBh': FunctionConfig = { 'fn': 'LOFT_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00ECh': FunctionConfig = { 'fn': 'PATIO_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00EDh': FunctionConfig = { 'fn': 'REAR_BEDROOM_RADIO', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00EEh': FunctionConfig = { 'fn': 'BEDROOM_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00EFh': FunctionConfig = { 'fn': 'BUNK_ROOM_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F0h': FunctionConfig = { 'fn': 'ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F1h': FunctionConfig = { 'fn': 'EXTERIOR_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F2h': FunctionConfig = { 'fn': 'FRONT_BEDROOM_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F3h': FunctionConfig = { 'fn': 'GARAGE_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F4h': FunctionConfig = { 'fn': 'KITCHEN_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F5h': FunctionConfig = { 'fn': 'LIVING_ROOM_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F6h': FunctionConfig = { 'fn': 'LOFT_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F7h': FunctionConfig = { 'fn': 'MAIN_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F8h': FunctionConfig = { 'fn': 'PATIO_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00F9h': FunctionConfig = { 'fn': 'REAR_BEDROOM_ENTERTAINMENT_SYSTEM', 'class' : DeviceClasses.IR_REMOTE_CONTROL };
  static '00FAh': FunctionConfig = { 'fn': 'LEFT_STABILIZER', 'class' : DeviceClasses.STABILIZER };
  static '00FBh': FunctionConfig = { 'fn': 'RIGHT_STABILIZER', 'class' : DeviceClasses.STABILIZER };
  static '00FCh': FunctionConfig = { 'fn': 'STABILIZER', 'class' : DeviceClasses.STABILIZER };
  static '00FDh': FunctionConfig = { 'fn': 'SOLAR', 'class' : DeviceClasses.POWER };
  static '00FEh': FunctionConfig = { 'fn': 'SOLAR_POWER', 'class' : DeviceClasses.POWER };
  static '00FFh': FunctionConfig = { 'fn': 'BATTERY', 'class' : DeviceClasses.POWER };
  static '0100h': FunctionConfig = { 'fn': 'MAIN_BATTERY', 'class' : DeviceClasses.POWER };
  static '0101h': FunctionConfig = { 'fn': 'AUX_BATTERY', 'class' : DeviceClasses.POWER };
  static '0102h': FunctionConfig = { 'fn': 'SHORE_POWER', 'class' : DeviceClasses.POWER };
  static '0103h': FunctionConfig = { 'fn': 'AC_POWER', 'class' : DeviceClasses.POWER };
  static '0104h': FunctionConfig = { 'fn': 'AC_MAINS', 'class' : DeviceClasses.POWER };
  static '0105h': FunctionConfig = { 'fn': 'AUX_POWER', 'class' : DeviceClasses.POWER };
  static '0106h': FunctionConfig = { 'fn': 'OUTPUTS', 'class' : DeviceClasses.POWER };
  static '0107h': FunctionConfig = { 'fn': 'RAMP_DOOR', 'class' : DeviceClasses.DOOR };
  static '0108h': FunctionConfig = { 'fn': 'FAN', 'class' : DeviceClasses.FAN };
  static '0109h': FunctionConfig = { 'fn': 'BATH_FAN', 'class' : DeviceClasses.FAN };
  static '010Ah': FunctionConfig = { 'fn': 'REAR_FAN', 'class' : DeviceClasses.FAN };
  static '010Bh': FunctionConfig = { 'fn': 'FRONT_FAN', 'class' : DeviceClasses.FAN };
  static '010Ch': FunctionConfig = { 'fn': 'KITCHEN_FAN', 'class' : DeviceClasses.FAN };
  static '010Dh': FunctionConfig = { 'fn': 'CEILING_FAN', 'class' : DeviceClasses.FAN };
  static '010Eh': FunctionConfig = { 'fn': 'TANK_HEATER', 'class' : DeviceClasses.WATER_TANK_HEATER };
  static '010Fh': FunctionConfig = { 'fn': 'FRONT_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0110h': FunctionConfig = { 'fn': 'REAR_CEILING_LIGHT', 'class' : DeviceClasses.LIGHT };
  static '0111h': FunctionConfig = { 'fn': 'CARGO_LIGHT', 'class' : DeviceClasses.LIGHT };

  }
}
