import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.css']
})
export class AppShellComponent implements OnInit {
  version = '1.180817.4c0329736026f20fa7ba4bcb96b70c92c8d3a829';
  loadingComplete = false;
  currentPage: string;
  year: number = new Date().getFullYear();

  constructor(private router: Router) {

  }

  ngOnInit() {
    console.log('App shell init.', 'TEST');

    this.router.events.subscribe(e => {
      if(!(e instanceof NavigationEnd)) {
        return;
      }

      const endEvent: NavigationEnd = <NavigationEnd>e;
      const url: string = '#' + endEvent.urlAfterRedirects;

      const urlParts: string[] = url.split('/');
      const pageNameLower: string = urlParts[urlParts.length - 1];

      const pageNameProper: string = pageNameLower.charAt(0).toUpperCase() + pageNameLower.substr(1, pageNameLower.length - 1);
      this.currentPage = pageNameProper;

      console.log('loadComplete emit');
    });
  }

  onLoadingComplete(e: boolean): void {
    console.log('onLoadingComplete');
    this.loadingComplete = true;
  }

  onConnectingComplete(e: any): void {
    console.log('onConnectingComplete', e);
  }
}
