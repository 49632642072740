import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitTextLength' })
export class LimitTextLengthPipe implements PipeTransform {

  transform(value: string, maxLength: number = 1000, args: any[]) {
    if (value) {
      if (value.length > maxLength) {
        value = value.substr(0, maxLength);
        const lastSpace = value.lastIndexOf(' ');
        value = value.substr(0, lastSpace);
        value += ' ...';
      }
    }
    return value;
  }
}
