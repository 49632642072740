import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import { Feed } from './model/feed';
import { environment } from '../../../environments/environment';

@Injectable()
export class FeedService {
  private rssToJsonServiceBaseUrl: string = 'https://api.rss2json.com/v1/api.json?api_key=' + environment.rss2jsonApiKey + '&count=10&rss_url=';

  private rssFeedsUrl: string = environment.newsAndTipsURL + '&ConfigType=rssfeeds';

  constructor(private httpClient: HttpClient) { }

  getFeedContent(url: string): Observable<Feed> {
    return this.httpClient.get<Feed>(this.rssToJsonServiceBaseUrl + url);
  }

  getFeeds(): Observable<any> {
    return this.httpClient.get(this.rssFeedsUrl);
  }

  private handleError (error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
