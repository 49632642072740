import { OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ThingService } from '../services/thing.service';
import { ActivatedRoute } from '@angular/router';
import { Thing } from '../models/thing';
import { Item } from '../models/item';
import { ThingFilter } from '../models/thing-filter';
import { OpenHabEvent } from '../models/open-hab-event';
import { Channel } from '../models/channel';
import { Observable } from 'rxjs';
import { OpenHabCacheService } from '../services/open-hab-cache.service';

export class ThingListComponent implements OnInit {
  public things: Thing[];
  public items: Item[];
  public activeThing: Thing;
  public selectedThingFilter: ThingFilter;
  public selectedThingTypes: string[];
  public selectedThingClasses: string[];
  public selectedThingFunctions: string[];

  constructor(
    protected rest: OpenHabCacheService,
    protected thingService: ThingService,
    protected route: ActivatedRoute,
    protected zone: NgZone,
    protected viewRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.fetchThingsAndItems(this.selectedThingFilter).subscribe();

    this.rest.registerEventHandler('ThingAddedEvent', this.thingAdded.bind(this));
    this.rest.registerEventHandler('ItemAddedEvent', this.itemAdded.bind(this));
    this.rest.registerEventHandler('ThingRemovedEvent', this.thingAdded.bind(this));
    this.rest.registerEventHandler('ItemRemovedEvent', this.itemAdded.bind(this));
  }

  fetchThingsAndItems(thingFilters: ThingFilter): Observable<Object>
  {

    return Observable.create((observer) =>
    {
      this.rest.getThings().then((things: Thing[]) =>
      {

        this.things = this.thingService.applyFiltersToThings(thingFilters, things);

        // sort by thing type, then name
        this.things = this.things.sort((a: Thing, b: Thing) =>
        {
          const aThingType: string = this.thingService.getThingType(a);
          const bThingType: string = this.thingService.getThingType(b);
          if (aThingType < bThingType) { return -1; }
          if (aThingType > bThingType) { return 1; }
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        if (this.things.length)
        {
          this.activateThing(this.things[0]);
        }

        // do not auto fetch (since we subscribe here)
        this.fetchItemsAndMerge().subscribe(null, null, () =>
        {
          observer.complete(); // done merging items, notify observers
        });
      });
    });
  }

  fetchItemsAndMerge(): Observable<Object>
  {
    return Observable.create((observer) =>
    {
      this.rest.getItems().then(items =>
      {
        this.items = <Item[]>items;
        this.things.forEach(thing =>
        {
          thing.channels.forEach(chan =>
          {
            const itemName: string = chan.linkedItems[0];
            chan.item = this.items.find( item => item.name === itemName );
            if (!chan.item) { return; }
            if (chan.item.type.toLowerCase() !== 'number') { return; }
            chan.item.state = Number(chan.item.state);
          });
        });
        observer.complete();
      });
    });
  }

  thingAdded(event: OpenHabEvent)
  {
    // TODO: add single thing to list
    console.warn('thingAdded, cache is stale.');
    this.fetchThingsAndItems(this.selectedThingFilter);
  }

  itemAdded(event: OpenHabEvent)
  {
    // TODO: add single item to list and merge with existing things
    // TODO: at least change to non-caching service first
    console.warn('itemAdded, cache is stale.');
    this.fetchItemsAndMerge();
  }

  thingRemoved(event: OpenHabEvent)
  {
    // TODO: remove single thing from list
    // TODO: at least change to non-caching service first
    console.warn('thingRemoved, cache is stale.');
    this.fetchThingsAndItems(this.selectedThingFilter);
  }

  itemRemoved(event: OpenHabEvent)
  {
    // TODO: remove single item from list and remove from existing things/channels
    // TODO: at least change to non-caching service first
    console.warn('itemRemoved, cache is stale.');
    this.fetchItemsAndMerge();
  }

  activateThing(thing: Thing)
  {
    this.activeThing = thing;
    window['activeThing'] = this.activeThing;
    this.viewRef.markForCheck();
  }

  getDetailChannels(thing: Thing): Channel[]
  {
    if (!thing) { return []; }
    const thingType: string = this.thingService.getThingType(thing);

    return thing.channels.filter(c =>
    {
      // not an online channel
      return (
        c.id !== 'online' &&
      // not a low or high temp channel on an hvac thing
        (!(thingType === 'hvac-thing' && (
            c.id === 'low-temperature' ||
            c.id === 'high-temperature'
        ))) &&
          // not a fault 'switch' / descriptor
          (!(c.id === 'output-disable' || c.id === 'output-disable-cause' || c.id === 'fault'))
        );
    });
  }

}
