import { StateDescription } from './state-description';

export class Item {
    type: string;
    name: string;
    label: string;
    category: string;
    tags: string[];
    groupNames: string[];
    link: string;
    state: string | number;
    transformedState: string;
    stateDescription: StateDescription = new StateDescription();
}
