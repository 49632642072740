import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  Input,
  NgZone,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';

import { Channel } from '../../../models/channel';
import { Light } from '../../../models/light';
import { LightTypes } from '../../../models/light-types';
import { LightModes } from '../../../models/light-modes';
import { LightRates, RgbLightRates } from '../../../models/light-rates';
import {
    MatSlideToggleChange,
    MatSliderChange,
    MatSelectChange,
    MatRadioChange,
    MatButtonToggleChange,
    ErrorStateMatcher
  } from '@angular/material/';

import { ValueLabel } from '../../../models/value-label';
import { LightsService } from '../../../services/lights.service';
import { OpenHabCacheService } from '../../../services/open-hab-cache.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-light-channel',
  templateUrl: './light-channel.component.html',
  styleUrls: ['./light-channel.component.css']
})
export class LightChannelComponent implements OnInit, AfterViewInit, OnChanges
{
  @Input() light: Light;
  lightRates: LightRates;

  matcher = new SleepTimeErrorStateMatcher();

  sleepTimeFormControl = new FormControl('',
  [
    Validators.min(0),
    Validators.max(255),
    Validators.pattern(/^\d*$/)
  ]);

  constructor (
    private rest: OpenHabCacheService,
    private lightsService: LightsService,
    protected zone: NgZone,
    protected viewRef: ChangeDetectorRef
  ) {
    this.lightRates = new LightRates();
  }

  ngOnInit()
  {
    console.log('ngOnInit > LightChannelComponent');
    // this should hopefully be called _after_ the ThingComponent handler? Since it's
    // registered last?
    this.rest.registerEventHandler('ItemStateChangedEvent', this.updateLight.bind(this));
  }

  ngAfterViewInit() {
    console.log(new Date().toISOString() + ': view initialized');
  }

  ngOnChanges(changes: SimpleChanges)
  {
    this.updateLight();
  }

  dimmerMoved(e: MatSliderChange)
  {
    const dimmer: Channel = this.getChannelByProperty('id', 'dimmer');
    this.rest
      .sendCommand(dimmer.item.name, String(e.value))
      .then(console.log);
  }

  sleepChanged(e: Event)
  {
    const sleep: Channel = this.getChannelByProperty('id', 'sleep');
    const sleepTimerMinutes: number = Number((<HTMLInputElement>e.target).value);
    this.rest
      .sendCommand(sleep.item.name, String(sleepTimerMinutes))
      .then(console.log);
  }

  switchChanged(mode: string)
  {
    const switchChannel: Channel = this.getChannelByProperty('id', this.light.type === LightTypes['DIMMERSWITCHED'] ? 'dimmer' : 'switch');
    this.rest
      .sendCommand(switchChannel.item.name, mode.toUpperCase())
      .then(console.log);
  }

  pickerMoved(e)
  {
    if (!e && !e.h) { return; }
    const mode: Channel = this.getChannelByProperty('id', 'mode');
    if (['BLINK', 'ON'].indexOf(<string>this.light.mode) === -1 && e.mouseevent)
    {
      this.rest
        .sendCommand(mode.item.name, 'ON')
        .then(console.log);
    }
    const hsv: any = this.toHSV(Number(e.h), Number(e.s), Number(e.v));
    this.colorChange(hsv);
  }

  colorMoved(e)
  {
    if (!e && !e.value) { return; }
    const hsv: any = this.toHSV(Number(e.value), this.light.saturation, this.light.luminosity);
    this.colorChange(hsv);
  }

  luminosityMoved(e: MatSliderChange)
  {
    const hsv: any = this.toHSV(this.light.hue, this.light.saturation, Number(e.value));
    this.colorChange(hsv);
  }

  saturationMoved(e: MatSliderChange)
  {
    const hsv: any = this.toHSV(this.light.hue, Number(e.value), this.light.luminosity);
    this.colorChange(hsv);
  }

  colorChange(hsv)
  {
    const hsvcolor: Channel = this.getChannelByProperty('id', 'hsvcolor');
    this.light.hue = <number>hsv.h;
    this.light.saturation = <number>hsv.s;
    this.light.luminosity = <number>hsv.v;
    this.rest
      .sendCommand(hsvcolor.item.name, [hsv.h, hsv.s, hsv.v].join(','))
      .then(console.log);
  }

  optionSelected(event: MatSelectChange)
  {
    const mode: Channel = this.getChannelByProperty('id', 'mode');
    this.rest
      .sendCommand(mode.item.name, String(event.value))
      .then((e) =>
      {
        console.log(e, this.light);
    });
  }

  updateLight()
  {
    console.log('updateLight()');
    this.lightsService.updateFromChannels(this.light, this.light.channels);
    // refresh view (usually a step behind for whatever reason)
    this.viewRef.markForCheck();
    // this.zone.run(() => console.log('state changed, refreshing light channel'));
  }

  getLightType(t): any
  {
    return LightTypes[t];
  }

  isEqual(a, b): boolean
  {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  getChannelByProperty(key: string, val: string): Channel
  {
    return this.light.channels.find(c =>
    {
      return c[key] === val;
    });
  }



  rateChanged(event: MatButtonToggleChange)
  {
    if (this.light.type === LightTypes['RGB'])
    {
      const rate: Channel = this.getChannelByProperty('id', 'speed');
      if (this.light.mode === 'BLINK')
      {
        // blink mode uses OFF and ON times
        this.light.rateOn = RgbLightRates[event.value].rateDefault;
        this.light.rateOff = RgbLightRates[event.value].rateDefault;
        // speed is a 16-bit number composed of an OFF and ON rate (8 bits)
        const rateCombined: number = ((this.light.rateOn << 8) + this.light.rateOff);
        this.rest
          .sendCommand(rate.item.name, String(rateCombined))
          .then((e) =>
          {
          console.log(e);
        });
      } else {
        // all other modes only use 16 bit speed
        this.light.rateOn = LightRates[event.value].rateDefault;
        this.light.rateOff = LightRates[event.value].rateDefault;
        this.rest
          .sendCommand(rate.item.name, String(this.light.rateOn))
          .then((e) =>
          {
          console.log(e);
        });
      }
      this.lightsService.updateRates(this.light);
      return;
    }
    const time1: Channel = this.getChannelByProperty('id', 'time1');
    const time2: Channel = this.getChannelByProperty('id', 'time2');
    this.light.rateOn = LightRates[event.value].rateDefault;
    this.light.rateOff = LightRates[event.value].rateDefault;

    this.lightsService.updateRates(this.light);

    this.rest
      .sendCommand(time1.item.name, String(this.light.rateOn))
      .then(console.log);
    this.rest
      .sendCommand(time2.item.name, String(this.light.rateOff))
      .then(console.log);
  }


  toHSV(h, s , v)
  {
    if (arguments.length === 1)
    {
        s = h.s, v = h.v, h = h.h;
    }
    const _h = h,
          _s = s,
          _v = v;


    return {
        h: parseInt(_h, 10),
        s: parseInt(_s, 10),
        v: parseInt(_v, 10)
    };
  }


}

/** Error when invalid control is dirty, touched, or submitted. */
export class SleepTimeErrorStateMatcher implements ErrorStateMatcher
{
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean
  {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
