import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';

import { Light } from '../../models/light';
import { ThingTypes } from '../../models/openhab-things';
import { ThingFilter } from '../../models/thing-filter';
import { ThingService } from '../../services/thing.service';
import { LightsService } from '../../services/lights.service';
import { ActivatedRoute } from '@angular/router';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { Observable } from 'rxjs';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';
import { Thing } from '../../models/thing';


@Component({
  selector: 'app-lights',
  templateUrl: './lights.component.html',
  styleUrls: ['./lights.component.css']
})
export class LightsComponent extends ThingListComponent implements OnInit {
  lights: Light[];
  activeLight: Light;

  constructor(rest: OpenHabCacheService, private lightsService: LightsService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, <ThingService>lightsService, route, zone, viewRef);
  }

  ngOnInit() {
    this.selectedThingFilter = ThingTypes['light-thing'];
    super.ngOnInit();
  }

  fetchThingsAndItems(thingFilters: ThingFilter): Observable<object> {
    return Observable.create(observer => {
      super.fetchThingsAndItems(this.selectedThingFilter).subscribe(null, null, () => {
        this.lights = this.things.map((t: Thing) => {
          return this.lightsService.CreateLight(t);
        });
        if (this.lights.length) {
          this.activateLight(this.lights[0]);
        }
      });
      observer.complete();
    });
  }

  activateLight(light: Light) {
    super.activateThing(<Thing>light);
    window['activeLight'] = this.activeLight = light;
  }

}
