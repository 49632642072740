
import {throwError as observableThrowError, TimeoutError } from 'rxjs';
import {tap, catchError, timeout} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
  } from '@angular/common/http';

import { DialogComponent } from '../ui/dialog/dialog.component';
import { ConnectionModalComponent } from '../ui/connection-modal/connection-modal.component';


@Injectable()
export class ConnectionNotifierService implements HttpInterceptor {
    dialogRef: MatDialogRef<DialogComponent>;
    modalRef: MatDialogRef<ConnectionModalComponent>;

    constructor(public dialog: MatDialog) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
      console.log(new Date().toISOString() + ': (ConnectionNotifierService) request made: url - ' + req.urlWithParams + ', body - ' + String(req.body));
        return next.handle(req)
          .pipe( tap( (ev: HttpEvent<any>) =>
          {

            if (ev instanceof HttpResponse) {
              console.log('processing response', ev);
            }

          // if (1==1) this.dispatcher.dispatch(...);
          }),
          timeout(15000),
          catchError(err =>
          {
            if (err instanceof HttpErrorResponse)
            {
              if (err.status === 200)
              {
                return Promise.resolve();
              }

              if (err.statusText === 'Unknown Error' && !(req.urlWithParams.indexOf('openstreetmap') > -1))
              {
                this.UnknownErrorDialog();
                return Promise.resolve();
              }

              if (err.status === 402)
              {
                this.UnknownErrorDialog();
                return Promise.resolve();
              }

              if (err.status === 511)
              {
                this.CommandTimeoutErrorDialog();
                return Promise.resolve();
              }
              console.log('Processing http error', err);
              return observableThrowError(err);
            }

            if (err instanceof TimeoutError)
            {
              this.CommandTimeoutErrorDialog();
              return Promise.resolve();
            }
          }) );

    }

    CommandTimeoutErrorDialog() {
      if (!this || (this && this.dialogRef))
      {
        return;
      }

      this.dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true
        , data : {
          title: 'Command Timeout Detected'
          , description: 'Your gateway did not respond to the request in a timely manner.'
          , retry: true
          , retryText: 'Ignore'
          , reconnect: true
          , reconnectText: 'Reconnect Session'
        }
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog closed: ${result}`);
        delete this.dialogRef;
      });
    }

    EventStreamErrorDialog() {
      if (!this || (this && this.dialogRef)) {
        return;
      }

      this.dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true
        , data : {
          title: 'Event Stream Disconnect Detected'
          , description: 'It appears we have lost the connection to your gateway.'
          , retry: true
          , retryText: 'Ignore'
          , reconnect: true
          , reconnectText: 'Reconnect Session'
        }
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog closed: ${result}`);
        delete this.dialogRef;
      });
    }

    UnknownErrorDialog() {
      if (!this || (this && this.dialogRef))
      {
        return;
      }
      this.dialogRef = this.dialog.open(DialogComponent,
      {
        disableClose: true
        , data : {
          title: 'Connection Issue Detected'
          , description: 'An issue was detected while attempting to communicate with your gateway.'
          , retry: true
          , retryText: 'Ignore'
          , reconnect: true
          , reconnectText: 'Reconnect Session'
        }
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog closed: ${result}`);
        delete this.dialogRef;
      });
    }

    ApiCallRetrying() {
      if (!this || (this && this.modalRef)) {
        return;
      }
      // TODO:  Put the dialog back in.
      // this.modalRef = this.dialog.open(DialogComponent,
      //   {
      //     disableClose: true
      //     , data:
      //     {
      //       title: 'Connection Issue Detected'
      //       , description: 'There was an issue while attempting to retrieve data from your gateway. Retrying the request.'
      //     }
      //   });
      // this.modalRef.afterClosed().subscribe(result => {
      //   console.log(`Dialog closed: ${result}`);
      //   delete this.modalRef;
      // });
    }

    EventStreamReconnecting()
    {
      if (!this || (this && this.modalRef))
      {
        return;
      }
      // TODO: Put the dialog back in.
      // this.modalRef = this.dialog.open(DialogComponent,
      //   {
      //     disableClose: true
      //     , data:
      //     {
      //       title: 'Connection Issue Detected'
      //       , description: 'There was an issue while attempting to communicate with your gateway. Attempting to reconnect.'
      //     }
      //   });
      // this.modalRef.afterClosed().subscribe(result =>
      // {
      //   console.log(`Dialog closed: ${result}`);
      //   delete this.modalRef;
      // });
    }
}
