import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-light-switch',
  templateUrl: './light-switch.component.html',
  styleUrls: ['./light-switch.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LightSwitchComponent implements OnInit {
  @Input() mode: string;
  @Output() switchChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  switchOn() {
    this.switchChanged.emit('on');
  }

  switchOff() {
    this.switchChanged.emit('off');
  }

}
