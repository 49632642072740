import {IdsCan} from './can-descriptors';
import {ThingFilter} from './thing-filter';

export class ThingTypes {

    static 'light-thing': ThingFilter = {
       filters: {
            includes: {
                 types: ['light-thing', 'switched-light-thing', 'rgb-light-thing']
            }
        }
    };
    static 'hvac-thing': ThingFilter = {
        filters: {
            includes: {
                types: ['hvac-thing']
            }
        }
    };
    static 'fan-thing': ThingFilter = {
        filters: {
            includes: {
                types: ['switch-thing'],
                classes: [IdsCan.DeviceClasses.FAN]
            }
        }
    };
    static 'power-thing': ThingFilter = {
        filters: {
            includes: {
                types: ['switch-thing', 'generator-thing'],
                classes: [IdsCan.DeviceClasses.POWER, IdsCan.DeviceClasses.GENERATOR]
            },
            excludes: {
                classes: IdsCan.Sections.TankSwitches
            }
        }
    };
    static 'tank-thing': ThingFilter = {
        filters: {
            includes: {
                types: ['tank-sensor-thing', 'switch-thing'],
                classes: [...IdsCan.Sections.TankSwitches, ...IdsCan.Sections.Tanks]
            }
        }
    };
}
