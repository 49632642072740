import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';

import { ThingTypes } from '../../models/openhab-things';
import { ThingService } from '../../services/thing.service';
import { ActivatedRoute } from '@angular/router';

import { ThingListComponent } from '../../thing-list/thing-list.component';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';

// TODO: there is a bug when switching between dashboard routes that causes the previous activeThing to remain

@Component({
  selector: 'app-fans',
  templateUrl: './fans.component.html',
  styleUrls: ['./fans.component.css']
})
export class FansComponent extends ThingListComponent implements OnInit {
  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
  }

  ngOnInit() {
    this.selectedThingFilter = ThingTypes['fan-thing'];
    super.ngOnInit();
  }


}
