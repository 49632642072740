import { Component, OnInit, Input, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Thing } from '../../models/thing';
import { Section } from '../../models/section';
import { Sections } from '../../models/section-definitions';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { ThingService } from '../../services/thing.service';
import { ThingFilter } from '../../models/thing-filter';
import { Observable } from 'rxjs';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent extends ThingListComponent implements OnInit {
  @Input() currentPage: string;
  sections: Array<Section>;
  public things: Thing[];

  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
  }

  ngOnInit() {
    console.log('main nav init.');
    // inject the dashboard @ index 0
    this.sections = [Sections[0]];

    this.rest.getThings().then(() => {
      for (let i = 1; i < Sections.length; i++) {
        const selectedThingFilter = Sections[i]['thing_type'];
        this.fetchThingsAndItems(selectedThingFilter).subscribe(null, null, () => {
          console.log(Sections[i]);
          if ( this.things && this.things.hasOwnProperty('length') && this.things.length > 0 ) {
            console.log('things >', this.things);
            this.sections.push(Sections[i]);
          }
        });

      }
    });
  }

  fetchThingsAndItems(thingFilters: ThingFilter): Observable<Object> {

    return Observable.create((observer) => {
      this.rest.getThings().then((things: Thing[]) => {
        this.things = this.thingService.applyFiltersToThings(thingFilters, things);
        observer.complete();
      });
    });

  }

}
