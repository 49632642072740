import { Component, OnInit, Input, AfterViewInit, DoCheck, Output, EventEmitter } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { FeedService } from './feed.service';
import { FeedEntry } from './model/feed-entry';

// Add the RxJS Observable operators we need in this app.
import './rxjs-operators';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, AfterViewInit, DoCheck {
  // The following statics are used to control when to use long/short versions of labels during resize.
  static rectRefreshIconInitial: ClientRect = undefined;
  static rectNewsFeedInitial: ClientRect = undefined;
  static classRSSFeedFormInitial: string = undefined;

  // Long and Short versions to use for labels based on available space.
  newsFeedLong = 'News Feed:';
  newsFeedShort = 'Feed:';

  newsFeedsLong = 'News Feeds';
  newsFeedsShort = 'News';

  rvTipsLong = 'RV Maintenance Tips';
  rvTipsShort = 'Tips';

  // tslint:disable-next-line: variable-name
  private _item;

  get item(): any {
    return this._item;
  }

  @Input()
  set item(val: any) {
    console.log('The inputted item was:', val);
    this._item = val;
  }

  @Output()
  content: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  selection: EventEmitter<string> = new EventEmitter<string>();

  // tslint:disable-next-line: variable-name
  private _selected: string = undefined;

  get selected(): string {
    return this._selected;
  }

  @Input()
  set selected(val: string) {
    console.log('Selected set to:', val);
    this._selected = val;
    this.refreshFeed();
    this.selection.emit(this._selected);
  }

  // When working with standalone reader during dev work, can uncomment the
  // following to select the first entry in the list of feeds from app.component.html.
  // selected = 'https%3A%2F%2Frvtravel.com%2Ffeed';

  feeds: Array<FeedEntry> = [];

  // tslint:disable-next-line: variable-name
  private _radioSelection = '1';

  get radioSelection(): string {
    return this._radioSelection;
  }

  @Input()
  set radioSelection(val: string) {
    this._radioSelection = val;
    console.log('RadioSelection set: ', val);
  }

  radioChange($event: MatRadioChange) {
    console.log($event.source.name, $event.value);
    this.radioSelection = $event.value;
    this.content.emit(this.radioSelection);
  }

  constructor(
    private feedService: FeedService
  ) {}

  ngOnInit() {
    this.refreshFeed();
  }

  ngAfterViewInit() {
    // Wait until far along in the lifecycle to capture the initial dimensions.
    if (!NewsComponent.rectNewsFeedInitial && document.getElementById('news-feed-without-margins')) {
      // Capture the size of the bounding rectangles for the news feed and radio group when using the default long labels.
      const newsFeed = document.getElementById('news-feed-without-margins');
      NewsComponent.rectNewsFeedInitial = newsFeed.getBoundingClientRect();

      const refreshIcon = document.getElementById('refresh-icon');
      NewsComponent.rectRefreshIconInitial = refreshIcon.getBoundingClientRect();

      const rssFeedForm = document.getElementById('rss-feed-form');
      NewsComponent.classRSSFeedFormInitial = rssFeedForm.className;
    }
  }

  ngDoCheck() {
    this.onResized(undefined);
  }

  selectionChanged(event: any) {
    this.refreshFeed();
  }

  onResized(event: ResizedEvent) {
    const refreshIcon = document.getElementById('refresh-icon');
    if (!refreshIcon) {
      return;
    }
    const rectRefreshIcon: ClientRect = refreshIcon.getBoundingClientRect();

    const newsFeed = document.getElementById('news-feed-without-margins');
    const rectNewsFeed: ClientRect = newsFeed.getBoundingClientRect();

    const newsFeedLabel = document.getElementById('news-feed-label');
    const rssFeedForm = document.getElementById('rss-feed-form');

    if (rectNewsFeed.right >= rectRefreshIcon.left) {
      newsFeedLabel.innerText = this.newsFeedShort;
      rssFeedForm.className = 'rss-feed-form-narrow ' + rssFeedForm.className;
    } else {
      // The div is using auto-margin, therefore we must subtract 1/2 of the diff in width between large label and short label
      // from the computation which determines where the right edge of the field would be if the large label were used.
      const widthDiff = NewsComponent.rectNewsFeedInitial.width - rectNewsFeed.width;
      if (rectNewsFeed.left - widthDiff / 2 + NewsComponent.rectNewsFeedInitial.width < rectRefreshIcon.left) {
        newsFeedLabel.innerText = this.newsFeedLong;
        rssFeedForm.className = NewsComponent.classRSSFeedFormInitial;
      }
    }
  }

  refreshFeed() {
    if (this.selected) {
      this.feeds.length = 0;
      // Adds 1s of delay to provide user's feedback.
      this.feedService.getFeedContent(this.selected).delay(1000)
          .subscribe(
              feed => this.feeds = feed.items,
              error => {
                console.log(error);
                this.feeds = [{
                  title: 'Unable to retrieve news.',
                  pubDate: null,
                  link: null,
                  categories: null,
                  content: 'Unable to get the news.',
                  guid: null,
                  thumbnail: null,
                  author: null,
                  description: null
                }];
              }
            );
    }
  }

}
