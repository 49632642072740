import { Component, OnInit, ElementRef, Input, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges, AfterViewChecked } from '@angular/core';
import { Mouse } from '../../models/mouse';
import {Observable, fromEvent} from 'rxjs';


import * as $ from 'jquery';


@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.css']
})
export class ColorPickerComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() hue: number = 0;
  @Input() saturation: number = 100;
  @Input() luminosity: number = 50;
  @Input() range: number;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('palette', { static: true, read: ElementRef }) paletteElem: ElementRef;
  @ViewChild('handle', { static: true, read: ElementRef }) handleElem: ElementRef;
  @ViewChild('cover', { static: true, read: ElementRef }) coverElem: ElementRef;
  @ViewChild('swatch', { static: true, read: ElementRef }) swatchElem: ElementRef;

  angle: number;
  relativeCenter: any;
  absoluteCenter: number;
  r: number;

  constructor() {  }


  ngOnInit(): void {
    const palette: Element = <Element>this.paletteElem.nativeElement;
    const rect: ClientRect = palette.getBoundingClientRect();

    this.relativeCenter = {
        x: rect.width / 2,
        y: rect.height / 2
    };
    this.r = this.relativeCenter.x * .8; // 80% of half the width of the pallet

    fromEvent(document.body, 'mouseup')
      .subscribe(e => {
        if (!Mouse.isDown) { return; }
        this.mouseUp(<MouseEvent>e);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setHandle();
  }

  ngAfterViewChecked(): void {
    this.setHandle();
  }

  setHandle(): void {
    if (!this.relativeCenter) { return; }
    this.angle = (this.hue * 1 / (this.range || 360)) * (2 * Math.PI) || 0;
    this.moveHandle({
      x: this.relativeCenter.x + this.r * Math.cos(this.angle),
      y: this.relativeCenter.y + this.r * Math.sin(this.angle)
    });
  }

  onMouseMove(e: MouseEvent) {
    if (!Mouse.isDown) { return false; }
    console.log('drag picker');
    this.moveHandle({
        x: e.offsetX,
        y: e.offsetY
    });
  }


  mouseDown(e: MouseEvent) {
    console.log('mousedown');
    Mouse.isDown = true;
  }

  mouseUp(e: MouseEvent) {
    console.log('mouseup');
    this.change.emit({h: +this.hue, s: +this.saturation, v: +this.luminosity, mouseevent: e});
    Mouse.isDown = false;
  }

  selectWhite(e: MouseEvent) {
    this.hue = 0;
    this.saturation = 0;
    this.luminosity = 100;
    this.mouseUp(e);
  }
  selectRed(e: MouseEvent) {
    this.hue = 0;
    this.saturation = 100;
    this.luminosity = 50;
    this.mouseUp(e);
  }
  selectGreen(e: MouseEvent) {
    this.hue = 120;
    this.saturation = 100;
    this.luminosity = 50;
    this.mouseUp(e);
  }
  selectBlue(e: MouseEvent) {
    this.hue = 240;
    this.saturation = 100;
    this.luminosity = 50;
    this.mouseUp(e);
  }

  moveHandle(e) {
    const handle: Element = <Element>this.handleElem.nativeElement;
    this.angle = this.getAngle(this.relativeCenter, {
        x: e.x,
        y: e.y
    });
    $(handle).css( 'left', String(this.relativeCenter.x + this.r * Math.cos(this.angle) - (handle.clientWidth / 2) - 8) + 'px');
    $(handle).css( 'top', String(this.relativeCenter.y + this.r * Math.sin(this.angle) - (handle.clientHeight / 2) - 8) + 'px');

    this.onAngleChange();
  }

  onAngleChange() {
    let swatch = <Element>this.swatchElem.nativeElement;
    let hue = this.angle * (180 / Math.PI); // Convert angle to degrees
    hue = hue < 0 ? hue + 360 : hue;
    let rgbModel = this.hslToRgb(hue, this.saturation, this.luminosity / 1.2);
    let color = 'rgb(' + rgbModel.red + ', ' + rgbModel.green + ', ' + rgbModel.blue + ')';
    $(swatch).css('background-color', color);
    this.hue = hue * 1 / 360 * (this.range || 360); // Scale back to range, defaults range to 0-360
  }

  getAngle(p1, p2) {
    return Math.atan2(p2.y - p1.y, p2.x - p1.x);
  }

  hslToHex(h, s, l) {
    let colorModel = this.hslToRgb(h, s, l);
    return this.rgbToHex(colorModel.red, colorModel.green, colorModel.blue);
  }

  rgbToHex(r, g, b) {
    return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  hslToRgb(h, s, l) {
    let r, g, b;
    h = h / 360;
    s = s / 100;
    l = l / 100;
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = this._hue2rgb(p, q, h + 1 / 3);
        g = this._hue2rgb(p, q, h);
        b = this._hue2rgb(p, q, h - 1 / 3);
    }

    return {
        red: Math.round(r * 255),
        green: Math.round(g * 255),
        blue: Math.round(b * 255)
    };
  }

  _hue2rgb(p, q, t) {
    if (t < 0) {t += 1;}
    if (t > 1) {t -= 1;}
    if (t < 1 / 6) {return p + (q - p) * 6 * t; }
    if (t < 1 / 2) {return q; }
    if (t < 2 / 3) {return p + (q - p) * (2 / 3 - t) * 6; }
    return p;
  }

}
