import {
  Component,
  OnInit,
  Input,
  NgZone,
  ChangeDetectorRef
} from '@angular/core';

import { Thing } from '../models/thing';
import { Channel } from '../models/channel';
import { DisplayChannels } from '../models/display-channels.enum';
import { ThingIcons } from '../models/thing-icons.enum';
import { ThingService } from '../services/thing.service';
import { OpenHabEvent } from '../models/open-hab-event';
import { OpenHabCacheService } from '../services/open-hab-cache.service';
import { ChannelNumberFormat } from '../models/channel-number-format.enum';

@Component({
  selector: 'app-thing',
  templateUrl: './thing.component.html',
  styleUrls: ['./thing.component.css']
})
export class ThingComponent implements OnInit
{

  @Input() public thing: Thing;
  public onlineChannel: Channel;
  public displayChannel: Channel;
  public ChannelNumberFormat: ChannelNumberFormat;

  constructor(
    protected rest: OpenHabCacheService,
    protected thingService: ThingService,
    protected zone: NgZone,
    protected viewRef: ChangeDetectorRef
  )
  { }

  ngOnInit()
  {
    console.log('ngOnInit > ThingComponent');

    this.ChannelNumberFormat = ChannelNumberFormat;
    this.rest.registerEventHandler('ItemStateChangedEvent', this.stateChanged.bind(this));
    this.onlineChannel = this.getOnlineChannel();
    this.displayChannel = this.getDisplayChannel();
  }

  isNumber(val): boolean
  {
    return typeof val === 'number';
  }

  stateChanged(event: OpenHabEvent)
  {
    const itemName: string = this.rest.getEventItemName(event);
    if (!itemName) { return; }

    const channel: Channel = this.thing.channels.find(i =>
    {
      return i.linkedItems.indexOf(itemName) > -1;
    });

    /** can't proceed without the channel definitely defined */
    if (!channel || !channel.item) { return; }

    const chanType: string = channel.item.type.toLowerCase();
    const newState: string | number = chanType === 'number' ? Number(event.payload.value) : event.payload.value;
    const currentState: string | number = chanType === 'number' ? Number(channel.item.state) : channel.item.state;

    if (currentState === newState) { return; }

    channel.item.state = newState;

    // refresh view (usually a step behind for whatever reason)
    // this.zone.run(() => console.log('state changed, refreshing thing'));
    this.viewRef.markForCheck();
  }

  getThingIconClass(): string
  {
    const onlineState = this.getThingState();
    if (['LOCKED', 'OFFLINE'].indexOf(onlineState) > -1)
    {
      return ThingIcons[onlineState];
    }
    const thingClass: string = this.thingService.getThingFunctionClass(this.thing);
    const thingType: string = this.thingService.getThingType(this.thing);
    if (thingType.indexOf('rgb') !== -1)
    {
      return ThingIcons[thingClass] + ' rgb';
    }
    return ThingIcons[thingClass];
  }

  getThingState(): string
  {
    const onlineChannel: Channel = this.getOnlineChannel();
    if (!(onlineChannel && onlineChannel.item && onlineChannel.item.state))
    {
      return '';
    }
    return String(onlineChannel.item.state);
  }

  getOnlineChannel(): Channel
  {
    return this.thing.channels.find(c =>
    {
      if (!c) { return false; }
      return c.id === 'online';
    });
  }

  getDisplayState()
  {
    return this.thingService.getDisplayState(this.displayChannel);
  }

  getDisplayChannel(): Channel
  {
    const thingType: string = this.thingService.getThingType(this.thing);
    const channelId: string = DisplayChannels[thingType];
    return this.thing.channels.find(c =>
    {
      if (!c) { return false; }
      return c.id === channelId;
    });
  }

  getChannelByProperty(key: string, val: string): Channel
  {
    return this.thing.channels.find(c =>
    {
      return c[key] === val;
    });
  }

}
