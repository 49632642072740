import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MapTouchEvent } from 'mapbox-gl';
import * as mapboxgl from  'mapbox-gl/dist/mapbox-gl';
import { ReverseGeocodingResult } from '../../models/reverse-geocoding-result';
import { Feature } from '../../models/feature';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LocationComponent implements OnInit, OnDestroy {

  static CLEAR_ICON_DELAY_SECONDS: number = 15;
  static PLACE_ACCURACY_CUTOFF_METERS: number = 50;
  static DEFAULT_ZOOM: number = 15;

  static thingProvidedLocationInfo: boolean = false;

  circleSize: number = 0;
  position: Position;
  locationDescription: string;
  locationAddress: string;
  iconUrl: string = '/static/assets/images/marker.png';
  placeImageUrl: string = '';
  iconRotation: number = 0;

  @ViewChild('marker', { static: true, read: ElementRef }) marker: ElementRef;
  markerObject: mapboxgl.Marker;
  @ViewChild('map', { static: true, read: ElementRef }) map: ElementRef;
  mapObject: mapboxgl.Map;
  @ViewChild('accuracyCircle', { static: true, read: ElementRef }) accuracyCircle: ElementRef;
  accuracyCircleObject: mapboxgl.Marker;


  private iconLastSet: number = 0;
  private positionSubscription: Subscription;

  constructor(private locationService: LocationService)
  {

  }

  ngOnInit()
  {
    (mapboxgl as any).accessToken = environment.mapBoxApiKey;

    this.mapObject = new mapboxgl.Map({
      container: this.map.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v9',
      zoom: LocationComponent.DEFAULT_ZOOM
    });

    this.markerObject = new mapboxgl.Marker(this.marker.nativeElement)
      .setLngLat(this.mapObject.getCenter())
      .addTo(this.mapObject);

    this.accuracyCircleObject = new mapboxgl.Marker(this.accuracyCircle.nativeElement)
      .setLngLat(this.mapObject.getCenter())
      .addTo(this.mapObject);

    // let firstCall: boolean = true;
    this.positionSubscription = this.locationService.getPositionObservable().subscribe((position: Position) =>
    {
      if (position && position.coords && !isNaN(position.coords.latitude)) {
        LocationComponent.thingProvidedLocationInfo = true;
        this.updateLocation(position);
      }
    });

    setTimeout(() => {
      if (!LocationComponent.thingProvidedLocationInfo) {
        const pos = this.locationService.getLocationPositionFromBrowser();
        pos.then((val) => {
                    console.log('Location::', val);
                    setTimeout(() => this.updateLocation({'coords': val.coords, 'timestamp': val.timestamp}), 1);
                  },
                (err) => {
                    console.error(err);
                }
        );
      }
    }, 6000);
    // //TODO:  Remove this line of code.
    // this.updateLocation({'coords': {
    //                       'latitude': 42.5508193969727,
    //                       'longitude': -83.0420074462891,
    //                       'accuracy': 33186.9,
    //                       'speed': 0,
    //                       'heading': 0,
    //                       'altitude': 0,
    //                       'altitudeAccuracy': 1
    //                     },
    //                     'timestamp': 1587672698147
    //                   });
  }

  getCircleSizeFromAccuracy(accuracy: number): number
  {
    const pixelsPerTile: number = 512;
    const numTiles: number = Math.pow(2, this.mapObject.getZoom());
    const metersPerTile: number = Math.cos(this.mapObject.getCenter().lat * Math.PI / 180) * 40075017 / numTiles;
    const pixelsPerMeter: number = pixelsPerTile / metersPerTile;
    return accuracy * pixelsPerMeter;
  }

  updateLocation(position: Position)
  {
    const self: LocationComponent = this;
    const velocityUnchanged = this.position &&
      position.coords.latitude === this.position.coords.latitude &&
      position.coords.longitude === this.position.coords.longitude &&
      position.coords.speed === this.position.coords.speed &&
      position.coords.heading === this.position.coords.heading;

    this.circleSize = this.getCircleSizeFromAccuracy(position.coords.accuracy);

    const secondsSinceIconLastSet = (Date.now() - this.iconLastSet) / 1000;
    if (!position.coords.speed && secondsSinceIconLastSet >= LocationComponent.CLEAR_ICON_DELAY_SECONDS)
    {
      this.iconUrl = '/static/assets/images/marker.png';
      this.iconRotation = 0;
    }

    if (velocityUnchanged)
    {
      return;
    }
    this.position = position;

    // set center for MapBox
    const newCenter: number[] = [this.position.coords.longitude, this.position.coords.latitude];
    this.mapObject.setCenter(newCenter);
    this.markerObject.setLngLat(newCenter);
    this.accuracyCircleObject.setLngLat(newCenter);

    // show correct arrow for current heading if moving
    if (this.position.coords.speed)
    {
      this.iconLastSet = this.position.timestamp;
      this.iconRotation = this.position.coords.heading || 0;
      this.iconUrl = '/static/assets/images/location-arrow.png';
    }

    this.locationService.getLocationInfoFromPosition(position).subscribe((data) => {
      const result: ReverseGeocodingResult = <ReverseGeocodingResult>data;

      const addressFeature: Feature = result.features.find(f => f.id.includes('address'));
      const placeFeature: Feature = result.features.find(f => f.id.includes('place'));

      let city: string;
      let state: string;

      if (addressFeature)
      {
        const road: string = addressFeature.text;
        city = addressFeature.context.find(c => c.id.includes('place')).text;
        state = addressFeature.context.find(c => c.id.includes('region')).text;
        this.locationAddress = road + ',' + city + ', ' + state;
      } else if (placeFeature)
      {
        city = placeFeature.text;
        state = placeFeature.context.find(c => c.id.includes('region')).text;
        this.locationAddress = city + ', ' + state;
      }

      if (city && state)
      {
        this.locationService.getImageForPlace(city, state).subscribe((imageUrl) => {
          this.placeImageUrl = (imageUrl || '');
        });
      }

      // just use the city as the place if the location is not accurate
      if (position.coords.accuracy > LocationComponent.PLACE_ACCURACY_CUTOFF_METERS)
      {
        this.locationDescription = city;
        return;
      }

      this.locationService.getPlaceInfoFromPosition(position).subscribe((placeData) => {
        const places: ReverseGeocodingResult = <ReverseGeocodingResult>placeData;
        // "point of interest"
        const resPlaceFeature: Feature = result.features.find(f => f.id.includes('poi'));
        if (!resPlaceFeature)
        {
          this.locationDescription = city;
          return;
        }
        this.locationDescription = resPlaceFeature.text;
      });

    });
  }
  
  ngOnDestroy()
  {
    this.positionSubscription.unsubscribe();
  }
}
