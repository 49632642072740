// The weatherApiKey is a freebie and is tied to account mhonkanen@lci1.com, and is for openweathermap.org.
// The mapBoxApiKey is for a pay-as-you-go plan and is tied to account jburns-oorjitham@lci1.com, and is for mapbox.com.
// The rss2jsonApiKey is for a 12month pro paid plan, expiring 4/30/2021, for rss2json.com and was purchased by Jamie.Lopiccolo@idselectronics.com.

export const environment = {
  production: false,
  weatherApiKey: 'f29bb3c1df2be96030fc5c6c15ac99f2',
  lang: 'en',
  mapBoxApiKey: 'pk.eyJ1IjoibGlwcGVydGNvbXBvbmVudHMiLCJhIjoiMWYzOWNlMzRjZDVjMWZmMzA4ZWFhNjBhYjlmODFkOGIifQ.6HjKgWHi-DfW1TnEAhPlPw',
  rss2jsonApiKey: 'yr6plg3jjghbvwggqzkh2wwfdiqor8vgwdbdsvzg',
  newsAndTipsURL: 'https://func-onecontrol-landing-api-01-stage.azurewebsites.net/api/ClientConfigInfo?code=RaQji7obiQ0bjiszPji5b5yvAkx/Vwi1upq43cDG2GAhVMgTKc67Pg=='
};
