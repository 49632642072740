import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { WeatherSettings, TemperatureScale, ForecastMode, WeatherLayout } from '../../../../node_modules/angular-weather-widget/dist';
import { LocationService } from '../../services/location.service';
import { Observable ,  Subscription } from 'rxjs';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WeatherComponent implements OnInit, OnDestroy
{
  static UPDATE_FREQUENCY_MINUTES: number = 60;
  static thingProvidedLocationInfo: boolean = false;

  @ViewChild('weatherWidget', { static: false }) weatherWidget;

  private positionSubscription: Subscription;
  private lastUpdateUnixTime: number = 0;


  public settings: WeatherSettings = {
    location: {
      latLng: {
        lat: 0,
        lng: 0
      }
    },
    backgroundColor: '#FFFFFF',
    color: '#000000',
    width: '100%', // 125% to account for scaling
    height: '460px',
    showWind: false,
    scale: TemperatureScale.FAHRENHEIT,
    forecastMode: ForecastMode.GRID,
    showDetails: false,
    showForecast: true,
    layout: WeatherLayout.NARROW,
    language: 'en'
  };

  constructor(private locationService: LocationService) { }

  ngOnInit() {
    this.positionSubscription = this.locationService.getPositionObservable()
      .subscribe((position: Position) => {
      if (position && position.coords && !isNaN(position.coords.latitude)) {
        WeatherComponent.thingProvidedLocationInfo = true;
        this.updateWeather(position);
      }
    });

    console.log('Location:');
    setTimeout(() => {
      if (!WeatherComponent.thingProvidedLocationInfo) {
        const pos = this.locationService.getLocationPositionFromBrowser();
        pos.then((val) => {
                    console.log('Location::', val);
                    setTimeout(() => this.updateWeather({'coords': val.coords, 'timestamp': val.timestamp}), 1);
                  },
                (err) => {
                    console.error(err);
                }
        );
      }
    }, 6000);
    // setTimeout(
    //     //TODO:  Remove this line of code.
    //     () => this.updateWeather(
    //       {'coords': {
    //         'latitude': 42.5508193969727,
    //         'longitude': -83.0420074462891,
    //         'accuracy': 33186.9,
    //         'speed': 0,
    //         'heading': 0,
    //         'altitude': 0,
    //         'altitudeAccuracy': 1
    //       },
    //       'timestamp': 1587672698147
    //       }
    //     ), 1);
  }

  updateWeather(position: Position)
  {
    if (!this.weatherWidget || !this.weatherWidget.settings)
    {
      console.log('weatherWidget settings not found.');
      return;
    }

    const currentUnixTime = Date.now();
    const coords: Coordinates = position.coords;
    const secondsSinceLastUpdate = (currentUnixTime -  this.lastUpdateUnixTime) / (60 * 1000);
    if (secondsSinceLastUpdate < WeatherComponent.UPDATE_FREQUENCY_MINUTES)
    {
       return;
  }

    console.log('Updating weatherWidget: ', this.weatherWidget.settings);
    this.lastUpdateUnixTime = currentUnixTime;
    this.settings.location.latLng.lat = coords.latitude;
    this.settings.location.latLng.lng = coords.longitude;
    this.weatherWidget.getWeather();
  }

  ngOnDestroy()
  {
    this.positionSubscription.unsubscribe();
  }

}
