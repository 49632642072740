import { Component, OnInit, Input, NgZone, ChangeDetectorRef } from '@angular/core';

import { Thing as Tank } from '../../../models/thing';
import { Channel } from '../../../models/channel';
import { ThingService } from '../../../services/thing.service';
import { ThingComponent } from '../../../thing/thing.component';
import { OpenHabCacheService } from '../../../services/open-hab-cache.service';

@Component({
  selector: 'app-tank',
  templateUrl: './tank.component.html',
  styleUrls: ['./tank.component.css']
})
export class TankComponent extends ThingComponent implements OnInit {

  @Input() public tankInstance: Tank;
  tankDetailsChannel: Channel;

  constructor(rest: OpenHabCacheService, serv: ThingService, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, serv, zone, viewRef);
  }

  ngOnInit() {
    console.log('ngOnInit > TankComponent');
    this.tankInstance = this.thing;
    this.tankDetailsChannel = this.getChannelByProperty('id', 'tank-level');


    super.ngOnInit();

  }

}
