import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

// Refer to the following site for other character entities.
// https://www.htmlhelp.com/reference/html40/entities/special.html

@Pipe({ name: 'decodeHtmlEntities' })
export class DecodeHtmlEntitiesPipe implements PipeTransform {

  transform(value: string) {
    value = value.replace(/&amp;/g, '&');
    value = value.replace(/&gt;/g, '>').replace(/&rsaquo;/g, '>');
    value = value.replace(/&lt;/g, '<').replace(/&lsaquo;/g, '<');
    value = value.replace(/&quot;/g, '"').replace(/&ldquo;/g, '"').replace(/&rdquo;/g, '"');
    value = value.replace(/&lsquo;/g, '`').replace(/&rsquo;/g, '\'');
    value = value.replace(/&tilde;/g, '~');
    value = value.replace(/&ndash;/g, '-');
    value = value.replace(/&#38;/g, '&');
    return value;
  }
}
