import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';

import { Thing } from '../../models/thing';
import { ThingTypes } from '../../models/openhab-things';
import { ThingFilter } from '../../models/thing-filter';
import { IdsCan } from '../../models/can-descriptors';
import { ThingService } from '../../services/thing.service';
import { ActivatedRoute } from '@angular/router';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { Observable } from 'rxjs';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';


@Component({
  selector: 'app-tanks',
  templateUrl: './tanks.component.html',
  styleUrls: ['./tanks.component.css']
})
export class TanksComponent extends ThingListComponent implements OnInit {
  tanks: Thing[];
  switches: Thing[];
  activeTank: Thing;
  Math: any;

  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
    this.Math = Math;
  }

  ngOnInit() {
    this.selectedThingFilter = ThingTypes['tank-thing'];
    super.ngOnInit();
  }

  fetchThingsAndItems(thingFilters?: ThingFilter): Observable<object> {
    return Observable.create(observer => {
      super.fetchThingsAndItems(thingFilters).subscribe(null, null, () => {
        console.log('things', this.things);
        this.tanks = this.things.filter( t => this.isTank(t));
        this.switches = this.things.filter( t => this.isTankSwitch(t));
      });
      observer.complete();
    });
  }

   isTank(t: Thing): boolean {
      return this.thingService.getThingType(t) === 'tank-sensor-thing';
   }

   isTankSwitch(t: Thing): boolean {
    return (IdsCan.Sections.TankSwitches.indexOf(t.configuration.class) > -1 && !this.isTank(t));
   }

}

