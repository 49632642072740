import { Component, OnInit, Input, NgZone, ChangeDetectorRef } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/material';

import { Light } from '../../../models/light';
import { Thing } from '../../../models/thing';
import { Channel } from '../../../models/channel';
import { LightsService } from '../../../services/lights.service';
import { ThingComponent } from '../../../thing/thing.component';
import { OpenHabCacheService } from '../../../services/open-hab-cache.service';

@Component({
  selector: 'app-light',
  templateUrl: './light.component.html',
  styleUrls: ['./light.component.css']
})
export class LightComponent extends ThingComponent implements OnInit
{

  @Input() public light: Light;
  dimmableLightChannel: Channel;
  switchedLightChannel: Channel;
  modeLightChannel: Channel;

  constructor(
    rest: OpenHabCacheService,
    lightsService: LightsService,
    zone: NgZone,
    viewRef: ChangeDetectorRef
  ) {
    super(rest, lightsService, zone, viewRef);
  }

  ngOnInit()
  {
    console.log('ngOnInit > LightComponent');
    this.updateChannels();
    super.ngOnInit();
  }

  updateChannels()
  {
    this.thing = <Thing>this.light;
    this.dimmableLightChannel = this.getChannelByProperty('id', 'dimmer');
    this.switchedLightChannel = this.getChannelByProperty('id', 'switch');
    this.modeLightChannel = this.getChannelByProperty('id', 'mode');
    // this.zone.run(() => console.log('state changed, refreshing light'));
  }

  lightSwitched(e: MatSlideToggleChange, switchThrown: Light): void
  {
    console.log('switchThrown', switchThrown, this.light, this.dimmableLightChannel, this.switchedLightChannel, this.modeLightChannel);
    this.updateChannels();
    let state: any;

    if (this.dimmableLightChannel)
    {
      state = this.modeLightChannel.item.state.toString();
      console.log('Dimmable light!', state);

      // if ((!e.checked && state === 'OFF') || (e.checked && state !== 'OFF')) { return; }
      this.rest
        .sendCommand(this.dimmableLightChannel.item.name, state === 'OFF' ? 'ON' : 'OFF')
        .then(console.log);
      return;
    }

    // const switchedLight = this.getChannelByProperty('id', 'switch');

    if (this.switchedLightChannel)
    {
      state = this.switchedLightChannel.item.state;
      console.log('Switched light!', state);
      this.rest.sendCommand(this.switchedLightChannel.item.name, state === 'OFF' ? 'ON' : 'OFF').then(console.log);
    }

  }

}
