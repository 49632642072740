import { Component, EventEmitter, Output, ViewContainerRef, Input, OnInit, enableProdMode } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ToastrService } from 'ngx-toastr';

// Set production mode
enableProdMode();

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit
{
  title = 'OneControl Cloud';
  @Input() currentPage: string;
  @Output() loadingComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router, public toastr: ToastrService, vcr: ViewContainerRef, angulartics2GoogleTagManager: Angulartics2GoogleTagManager)
  {

  }

  ngOnInit(): void
  {
    this.router.events.subscribe(e =>
    {
      if (!(e instanceof NavigationEnd))
      {
        return;
      }
      this.loadingComplete.emit(true);
    });
  }
}
