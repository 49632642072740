import { Component, NgZone, ChangeDetectorRef } from '@angular/core';

import { Thing } from '../../models/thing';
import { ThingTypes } from '../../models/openhab-things';
import { ThingFilter } from '../../models/thing-filter';
import { ThingService } from '../../services/thing.service';
import { Channel } from '../../models/channel';
import { ActivatedRoute } from '@angular/router';
import { TemperatureLabel } from '../../models/temp-label';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { Observable } from 'rxjs';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';

@Component({
  selector: 'app-current-temps',
  templateUrl: './current-temps.component.html',
  styleUrls: ['./current-temps.component.css']
})
export class CurrentTempsComponent extends ThingListComponent {
  tempZones: TemperatureLabel[] = [
    new TemperatureLabel('Zone 1', ''),
    new TemperatureLabel('Zone 2', ''),
    new TemperatureLabel('Zone 3', '')
  ];

  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
   }

  ngOnInit() {
    this.selectedThingFilter = ThingTypes['hvac-thing'];
    super.ngOnInit();
  }

  fetchThingsAndItems(thingFilter: ThingFilter): Observable<object> {
    return Observable.create(observer => {
      super.fetchThingsAndItems(thingFilter).subscribe(null, null, () => {
        this.tempZones = [];
        let hasOutsideTemp: number;
        // at this point, all things are fetched and all items merged
        this.things.forEach(thing => {
          let inside: Channel = this.getChannelByProperty(thing, 'id', 'inside-temperature');
          let outside: Channel = this.getChannelByProperty(thing, 'id', 'outside-temperature');

          this.tempZones.push(new TemperatureLabel(thing.label, inside.item.state, outside.item.state));
          if (outside.item.state) { hasOutsideTemp = this.tempZones.length - 1; }
        });
        if (hasOutsideTemp) {
          this.tempZones.push(new TemperatureLabel('Outside', this.tempZones[hasOutsideTemp].outsideTemperature));
        }
        // all zones gathered from things
        observer.complete();
      });
    });
  }

  getChannelByProperty(zone: Thing, key: string, val: string): Channel {
    return zone.channels.find(c => {
      return c[key] === val;
    });
  }

}
