import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { LightsComponent } from '../components/lights/lights.component';
import { HvacComponent } from '../components/hvac/hvac.component';
import { FansComponent } from '../components/fans/fans.component';
import { PowerComponent } from '../components/power/power.component';
import { TanksComponent } from '../components/tanks/tanks.component';
import { PreferencesComponent } from '../components/preferences/preferences.component';

import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }
  ,{
    path: 'dashboard',
    component: DashboardComponent
  }
  ,{
    path: 'lights',
    component: LightsComponent
  }
  ,{
    path: 'hvac',
    component: HvacComponent
  }
  ,{
    path: 'fans',
    component: FansComponent
  }
  ,{
    path: 'power',
    component: PowerComponent
  }
  ,{
    path: 'tanks',
    component: TanksComponent
  }
  ,{
    path: 'preferences',
    component: PreferencesComponent
  }
  ,{
    path: '**',
    component: PageNotFoundComponent
  }
];

// useHash for compatibility with Jetty in openHAB

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class OneControlRoutingModule { }
