import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { OpenHabService } from './open-hab.service';

import { Item } from '../models/item';
import { Thing } from '../models/thing';
import { ThingTypes } from '../models/openhab-things';

@Injectable()
export class OpenHabCacheService extends OpenHabService
{
  originalThingsFetch: Promise<Object|void>;
  originalItemsFetch: Promise<Object|void>;
  Things: Array<Thing>;
  Items: Array<Item>;

  constructor(http: HttpClient, dialog: MatDialog )
  {
    super(http, dialog);
  }

  getThings(): Promise<Object|void>
  {
    if (this.Things && this.Things.length)
    {
      return Promise.resolve(this.Things);
    }

    if (!this.originalThingsFetch)
    {
      this.originalThingsFetch = super.getThings().then((things: Array<Thing>) =>
      {
        return this.Things = things;
      });
    }
    return this.originalThingsFetch;
  }

  getThingsByType(thingType: ThingTypes): Promise<Object|void>
  {
    console.log('getThingsByType', thingType);

    return this.getThings().then((things: Array<Thing>) =>
    {
        for (const f in ThingTypes)
        {
          if (!ThingTypes.hasOwnProperty(f) || f !== thingType) { continue; }
          break;
        }
        return things;
    });

  }

  getItems(): Promise<Object|void>
  {
    if (this.Items && this.Items.length)
    {
      console.log('this.Items.length:', this.Items.length);
      return Promise.resolve(this.Items);
    }

    if (!this.originalItemsFetch)
    {
      console.warn('originalItemsFetch was null.');
      this.originalItemsFetch = super.getItems()
        .then((items: Array<Item>) =>
        {
          return this.Items = items;
        });
    }
    return this.originalItemsFetch;
  }

  getItem(itemName: string): Promise<Object|void>
  {
    return new Promise((resolve, reject) =>
    {
      this.getItems().then((items: Item[]) =>
      {
        const foundItem: Item = items.find((item) =>
        {
          return item.name === itemName;
        });
        resolve(foundItem);
      });
    });
  }

}
