import { Item } from './item';

export class Channel {
    channelTypeUID: string;
    configuration: any;
    defaultTags: string[];
    id: string;
    itemType: string;
    kind: string;
    linkedItems: string[];
    properties: any;
    uid: string;
    item: Item = new Item(); // only one item for our purposes (will fetch via REST when things list returned)
    unit: string; // sometimes used to store units label like in HVAC
}
