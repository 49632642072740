import { Injectable } from '@angular/core';
import { Thing } from '../models/thing';
import { Channel } from '../models/channel';
import { MagicValues } from '../models/magic-values';
import { ValueLabel } from '../models/value-label';
import { ThingFilter } from '../models/thing-filter';

@Injectable()
export class ThingService
{

  constructor() { }

  getThingType(thing: Thing): string
  {
    // console.log(thing);
    return thing.thingTypeUID.split(':')[1];
  }

  getThingFunctionClass(thing: Thing): string
  {
    return thing.configuration.class;
  }

  getDisplayState(channel: Channel)
  {
    let result: string = '';
    // thing does not have a display channel set yet
    if (!channel) { return result; }
    // item not initialized yet
    if (!channel.item) { return result; }
    const state: string = channel.item.state.toString();
    if (!state) { return result; }
    if (!channel.item.stateDescription ||
      !channel.item.stateDescription.options ||
      !channel.item.stateDescription.options.length)
      {
        if (channel.item.type.toLowerCase() === 'number')
        {
          result = MagicValues.replace(channel.id, Number(state));
        }
        // replace magic values if necessary
        result = MagicValues.replace(channel.id, state);
    } else {
      const matchingOption: ValueLabel = channel.item.stateDescription.options.find(vl =>
      {
        return vl.value === state;
      });
      result = matchingOption ? matchingOption.label : '';
    }

    if (channel.item.stateDescription && channel.item.stateDescription.pattern)
    {
      const patternParts: string[] = channel.item.stateDescription.pattern.split(' ');
      if (patternParts.length > 1)
      {
        result = result + ' ' + patternParts[1];
      }
    }

    return result;
  }

  applyFiltersToThings(thingFilters: ThingFilter, things: Thing[]):  Thing[]
  {
    if (!things) { console.error('no Things returned!'); return things; }
    if (!thingFilters) {  console.warn('no ThingFilter!'); return  things; }

    // filter things
    if (thingFilters.filters.includes)
    {
      things = this.filterThingsByTypes(things, thingFilters.filters.includes.types);
      things = this.filterThingsByClasses(things, thingFilters.filters.includes.classes);
      things = this.filterThingsByFunctionNames(things, thingFilters.filters.includes.functions);
    }

    // filter excluded things
    if (thingFilters.filters.excludes)
    {
      things = this.filterThingsByTypes(things, thingFilters.filters.excludes.types, true);
      things = this.filterThingsByClasses(things, thingFilters.filters.excludes.classes, true);
      things = this.filterThingsByFunctionNames(things, thingFilters.filters.excludes.functions, true);
    }
    return things;
  }


// filter things if list of thing types given
  filterThingsByTypes(things: Thing[], thingTypes: string[], reductive: boolean = false): Thing[]
  {
    if (!thingTypes || thingTypes.length === 0) { return things; }

    return things.filter(t =>
    {
      const thisThingType: string = this.getThingType(t);
      return reductive ? thingTypes.indexOf(thisThingType) === -1 : thingTypes.indexOf(thisThingType) > -1;
    });

  }

// filter things if list of thing Classes given
  filterThingsByClasses(things: Thing[], thingClasses: string[], reductive: boolean = false): Thing[]
  {
    if (!thingClasses || thingClasses.length === 0) { return things; }

    return things.filter(t =>
    {
      if (!(t.configuration && t.configuration.class)) { return false; }
      const thisThingClass: string = t.configuration.class;
      return reductive ? thingClasses.indexOf(thisThingClass) === -1 : thingClasses.indexOf(thisThingClass) > -1;
    });

  }

// filter things if list of thing functionNames given
  filterThingsByFunctionNames(things: Thing[], thingFunctions: string[], reductive: boolean = false): Thing[]
  {
    if (!thingFunctions || thingFunctions.length === 0) { return things; }

    return things.filter(t =>
    {
      if (!(t.configuration && t.configuration.fn)) { return false; }
      const thisThingFunction: string = t.configuration.fn;
      return reductive ? thingFunctions.indexOf(thisThingFunction) === -1 : thingFunctions.indexOf(thisThingFunction) > -1;
    });

  }

}
