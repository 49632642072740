import { MagicValue } from "./magic-value";

export class MagicValues {
    [index: number]: MagicValue;
    static defaults: MagicValues = [
        new MagicValue('inside-temperature', -128, '-'),
        new MagicValue('outside-temperature', -128, '-'),
        new MagicValue('inside-temperature', 47.9375, '-'),
        new MagicValue('outside-temperature', 47.9375, '-'),
        new MagicValue('low-temperature', 0, '-'),
        new MagicValue('high-temperature', 0, '-'),
        new MagicValue('low-temperature', -128, '-'),
        new MagicValue('high-temperature', -128, '-'),
        new MagicValue('temperature', -128, '-'),
        new MagicValue('temperature', 127.9961, '-'), // rounding error?
        new MagicValue('temperature', 128, '-')
    ];
    public static find(channelId: string, value: any) : MagicValue {
        // cast to array
        let defaults: MagicValue[] = <MagicValue[]>MagicValues.defaults;
        return defaults.find(mv => {
            return mv.channelId === channelId &&
                mv.originalValue == value;
        });
    }
    public static replace(channelId: string, value: any) : any {
        let magicValue: MagicValue = MagicValues.find(channelId, value);
        if(!magicValue){ return value; }
        return magicValue.replacementValue;
    }
}