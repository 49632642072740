import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Connection Error Dialog
 */
@Component({
    selector: 'app-dialog',
    template: `
        <h2 mat-dialog-title>{{title}}</h2>
        <mat-dialog-content>
            <img src="./assets/images/loading.gif">
            <p>{{description}}</p>
        </mat-dialog-content>
    `/* template */
  })
  export class ConnectionModalComponent implements OnInit {

    title: String;
    description: String;

  constructor(public dialogRef: MatDialogRef<ConnectionModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data['title'];
      this.description = data['description'];
    }
    ngOnInit() {
    }

    closeDialog(): void {
      this.dialogRef.close('RETRY');
    }
    reconnectCommunity(): void {
      top.location.href = 'https://onecontrolcloud.lci1.com/login';
    }
  }
