import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimeComponent implements OnInit {
  public dateTime: Date;
  private intervalKey: number;

  constructor() {
    // set current time
    this.dateTime = new Date(Date.now());
    // update the time to current time every second
    this.intervalKey = window.setInterval(() => {
      this.dateTime = new Date(Date.now());
    }, 1000); // checks every second for now
    // could maybe be every minute or so but, eh.
    // this helps if we ever change it to display
    // seconds
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    // stop interval from repeating now
    window.clearInterval(this.intervalKey);
  }

}
