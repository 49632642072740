import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { OpenHabCacheService as OriginalOpenHabCacheService } from '../open-hab-cache.service';


// Test cache service is exactly the same code except
// that it relies on the test open hab service
@Injectable()
export class OpenHabCacheService extends OriginalOpenHabCacheService  {
  constructor(http: HttpClient, public dialog: MatDialog) {
    super(http, dialog);
  }
}
