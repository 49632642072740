import { MagicValues } from "./magic-values";

export class TemperatureLabel {
    constructor(public label: string, public temperature: any, public outsideTemperature?: any){
        let temp: any = MagicValues.replace('inside-temperature', this.temperature);
        let outTemp: any = MagicValues.replace('outside-temperature', this.outsideTemperature);
        if(isNaN(temp) || typeof temp !== 'number'){
            this.temperature = '-';
        }
        if(isNaN(outTemp) || typeof outTemp !== 'number'){
            this.outsideTemperature = '-';
        }
    }
}