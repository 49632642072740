import { LightTypes } from './light-types';
import { LightModes } from './light-modes';
import { LightRates, RgbLightRates } from './light-rates';
import { Thing } from './thing';

export class Light extends Thing {
    name: string;
    type: LightTypes;
    mode: LightModes;
    options: any[];
    dimmer: number;
    rate: LightRates|RgbLightRates;
    rateOn: number;
    rateOff: number;
    sleep: number;
    hue: number;
    saturation: number;
    luminosity: number;

    constructor(t: Thing) {
        super();
        Object.assign(this, t);
    }
}
