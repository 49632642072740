import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ZoomComponent implements OnInit {

  @Output() increase: EventEmitter<any> = new EventEmitter();
  @Output() decrease: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  plusClicked(){
    this.increase.emit();
  }

  minusClicked(){
    this.decrease.emit();
  }

}
