import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';


import { Thing } from '../../models/thing';
import { ThingTypes } from '../../models/openhab-things';
import { ThingFilter } from '../../models/thing-filter';
import { ThingService } from '../../services/thing.service';
import { ActivatedRoute } from '@angular/router';
import { Channel } from '../../models/channel';
import { DialChannels } from '../../models/dial-channels';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';

// TODO: there is a bug when switching between dashboard routes that causes the previous activeThing to remain

@Component({
  selector: 'app-hvacs',
  templateUrl: './hvac.component.html',
  styleUrls: ['./hvac.component.css']
})
export class HvacComponent extends ThingListComponent implements OnInit {
  dialChannels: DialChannels;

  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
  }

  ngOnInit() {
    this.selectedThingFilter = ThingTypes['hvac-thing'];
    super.ngOnInit();
  }

  activateThing(thing: Thing) {
    super.activateThing(thing);
    this.dialChannels = this.getDialChannels(thing);
  }

  getDialChannels(thing: Thing): DialChannels {
    const result: DialChannels = null;
    if (!thing) { return result; }
    const thingType: string = this.thingService.getThingType(thing);
    // only hvacs have dials
    if (thingType !== 'hvac-thing') { return result; }
    // get low temp channel
    const lowTempChannel = thing.channels.find(c => {
      return c.id === 'low-temperature';
    });
    // get high temp channel
    const highTempChannel = thing.channels.find(c => {
      return c.id === 'high-temperature';
    });
    // get mode channel
    const modeChannel = thing.channels.find(c => {
      return c.id === 'hvac-mode';
    });
    // get temp channel
    const tempChannel = thing.channels.find(c => {
      return c.id === 'inside-temperature';
    });
    if (lowTempChannel && lowTempChannel.item && lowTempChannel.item.state) {
      lowTempChannel.item.state = Number(lowTempChannel.item.state);
    }
    if (highTempChannel && highTempChannel.item && highTempChannel.item.state) {
      highTempChannel.item.state = Number(highTempChannel.item.state);
    }

    if (tempChannel && tempChannel.item && tempChannel.item.stateDescription && tempChannel.item.stateDescription.pattern) {
      const patternParts: string[] = tempChannel.item.stateDescription.pattern.split(' ');
      if(patternParts.length > 1) {
        tempChannel.unit = patternParts[1];
      }
    }

    // combine and add to result
    return {
      first: lowTempChannel,
      second: highTempChannel,
      mode: modeChannel,
      temp: tempChannel
    };
  }

}
