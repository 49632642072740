import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';

import { OpenHabService } from '../../services/open-hab.service';
import { Thing } from '../../models/thing';
import { Item } from '../../models/item';
import { Channel } from '../../models/channel';
import { DisplayChannels } from '../../models/display-channels.enum';
import { ThingService } from '../../services/thing.service';
import { ActivatedRoute } from '@angular/router';
import { OpenHabEvent } from '../../models/open-hab-event';
import { Pair } from '../../models/pair';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';

// TODO: there is a bug when switching between dashboard routes that causes the previous activeThing to remain

@Component({
  selector: 'app-locks',
  templateUrl: './locks.component.html',
  styleUrls: ['./locks.component.css']
})
export class LocksComponent extends ThingListComponent {
  constructor(rest: OpenHabCacheService, thingService: ThingService, route: ActivatedRoute, zone: NgZone, viewRef: ChangeDetectorRef) {
    super(rest, thingService, route, zone, viewRef);
  }

  ngOnInit() {
    this.selectedThingTypes = ['switch-thing'];
    super.ngOnInit();
  }

}
