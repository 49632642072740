import { Channel } from './channel';

export class Thing {
    UID: string;
    configuration: any;
    editable: boolean;
    label: string;
    properties: any;
    statusInfo: any;
    thingTypeUID: string;
    channels: Channel[];
}
