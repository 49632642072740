import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { timeout, retry, tap } from 'rxjs/operators/';

import { OpenHabEvent } from '../models/open-hab-event';
import { ConnectionNotifierService } from './connection-notifier.service';
import { Thing } from '../models/thing';
import { IdsCan } from '../models/can-descriptors';

@Injectable()
export class OpenHabService
{
  protected static eventSource: EventSource;
  protected static eventSourceSubscribers = [];
  publisher: Observable<Object>;
  broker: Subject<Object>;
  eventStreamRetries: number = 0;
  apiCallRetries: number = 0;
  connectionNotifierService: ConnectionNotifierService;

  constructor(protected http: HttpClient, dialog: MatDialog)
  {
    this.connectionNotifierService = new ConnectionNotifierService(dialog);
    this.broker = new Subject();
    this.publisher = this.broker.asObservable();
    this.setupBroker();
  }

  getThings(): Promise<Object|void>
  {
    return this.makeApiCall('/rest/things')
      .then(this.AddFunctionNames)
      .catch(this.connectionNotifierService.UnknownErrorDialog);
  }

  getItems(): Promise<Object|void>
  {
    return this.makeApiCall('/rest/items')
      .catch(this.connectionNotifierService.UnknownErrorDialog);
  }

  getItem(itemName: string): Promise<Object|void>
  {
    return this.makeApiCall('/rest/items/' + itemName)
      .catch(this.connectionNotifierService.UnknownErrorDialog);
  }

  makeApiCall(url: string): Promise<Object | void>
  {
    return new Promise((accept, reject) =>
    {
      console.log(`Calling API: ${url}`);
      this.http.get(url)
        .pipe(timeout(2500), retry(3))
        .subscribe(
        (res) => {

          if (this.apiCallRetries > 0) {
            this.apiCallRetries = 0;
            this.connectionNotifierService.modalRef.close();
          }

          accept(res);

        }, (err) => {
            console.error(`An error was returned: ${err}`);
            this.connectionNotifierService.ApiCallRetrying();
            this.apiCallRetries++;
            return setTimeout(() => {
              console.warn(`retrying... attempt:${this.apiCallRetries}`);
              accept(this.makeApiCall(url));
            }, 1000 * (this.apiCallRetries < 60 ? this.apiCallRetries : 60));

        });

    });

  }

  getEventItemName(event: OpenHabEvent)
  {
    const itemName: string = event.topic.split('/').find(urlPart =>
    {
      // TODO: put idsmyrv prefix in constants file somewhere
      return urlPart.indexOf('idsmyrv') > -1;
    });

    return itemName;
  }

 /** ThingStatusInfoEvent, etc... */
  registerEventHandler(eventName: string, handler: (event: OpenHabEvent) => void)
  {

    this.getEvents().subscribe(event =>
    {
      const parsedEvent: OpenHabEvent = this.parseEvent(event);
      if (parsedEvent.type !== eventName)
      {
        return;
      }
      console.log(eventName, ' : ', parsedEvent);
      handler(parsedEvent);
    });
  }

  setupBroker(): void {
    // there can only be one event source
    if (!OpenHabService.eventSource) {
      console.log('Creating New eventSource.');
      OpenHabService.eventSource = this.setupEventSource();
    }

    OpenHabService.eventSource.addEventListener('message', (x: MessageEvent) => {
      return this.broker.next(x.data);
    });
  }

  setupEventSource(): EventSource
  {
    // full path is needed for polyfill
    const es = new EventSource(`${location.protocol}//${window.location.host}/rest/events?topics=smarthome/items/*/statechanged,smarthome/things/*`);

    es.addEventListener('open', (x: Event) => {
      /** Clear flapping ES when successfully opened */
      if (this.eventStreamRetries > 0) {
        this.eventStreamRetries = 0;
        this.connectionNotifierService.modalRef.close();
      }
    });

    es.addEventListener('error', async (x) => {
      console.error(x);

      this.destroyEventSource();

      this.connectionNotifierService.EventStreamReconnecting();
      this.eventStreamRetries++;

      setTimeout(() =>
      {
        console.warn(`reconnecting... attempt:${this.eventStreamRetries}`);
        this.setupBroker();
      }, 1000 * (this.eventStreamRetries < 60 ? this.eventStreamRetries : 60));


    });

    return es;
  }

  destroyEventSource(): void
  {
    if (!OpenHabService.eventSource)
    {
      return;
    }
    OpenHabService.eventSource.close();
    OpenHabService.eventSource = null;
    delete OpenHabService.eventSource;
  }

  getEvents(): Observable<Object>
  {
      return this.publisher;
  }

  parseEvent(event: any): OpenHabEvent
  {
    if (typeof event === 'string')
    {
      event = JSON.parse(event);
    }
    if (typeof event.payload === 'string')
    {
      event.payload = JSON.parse(event.payload);
    }
    return <OpenHabEvent>event;
  }

  sendCommand(itemName: string, command: string): Promise<Object|void>
  {
    return new Promise((accept, reject) =>
    {
      this.http
        .post('/rest/items/' + itemName, command)
        .pipe(timeout(4000), retry(1))
        .subscribe(accept, reject);
    })
    .catch((err) =>
      {
        console.error(err);
        this.connectionNotifierService.CommandTimeoutErrorDialog();
      });
  }

  AddFunctionNames(things: Thing[])
  {
    // add functionName to things
    return things
      .map((t) =>
      {
        if (t.configuration && t.configuration.functionName)
        {
          const thingConfig = IdsCan.Functions[<string>IdsCan.decimalToHex(t.configuration.functionName)];
          if (!thingConfig)
          {
            console.error('Could not locate definition for object at:', t.configuration.functionName);
            return t;
          }

          t.configuration.fn = thingConfig['fn'];
          t.configuration.class = thingConfig['class'];

        }
        return t;
      });
  }
}
