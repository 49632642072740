export class ThingIcons {
    /** function class to icon */
    // hvac
    static 'HVAC_CONTROL': string = 'fa-thermometer-three-quarters';
    static 'TEMPERATURE_SENSOR': string = 'fa-thermometer-three-quarters';
    // tanks
    static 'LP_TANK': string = 'fa-toggle-on';
    static 'LP_TANK_VALVE': string = 'fa-toggle-on';
    static 'WATER_TANK_HEATER': string = 'fa-toggle-on';
    static 'GAS_WATER_HEATER': string = 'fa-toggle-on';
    static 'TANK': string = 'fa-trello';
    // lights
    static 'LIGHT': string = 'fa-lightbulb-o';
    // generator
    static 'GENERATOR': string = 'fa-power-off';
    // fan
    static 'FAN': string = 'fa-crosshairs';
    // offline
    static 'OFFLINE': string = 'fa-times';
    // locked
    static 'LOCKED': string = 'fa-lock';
}
