import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';

import { OpenHabService } from '../../services/open-hab.service';
import { Thing } from '../../models/thing';
import { Item } from '../../models/item';
import { Channel } from '../../models/channel';
import { DisplayChannels } from '../../models/display-channels.enum';
import { ThingService } from '../../services/thing.service';
import { ActivatedRoute } from '@angular/router';
import { OpenHabEvent } from '../../models/open-hab-event';
import { Pair } from '../../models/pair';
import { ThingListComponent } from '../../thing-list/thing-list.component';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';
import { FeedService } from '../news/feed.service';

// TODO: there is a bug when switching between dashboard routes that causes the previous activeThing to remain

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends ThingListComponent implements OnInit
{
  // TODO: remove when dashboard updated
  dialChannelPair: Pair<Channel>;
  loadingComplete: Boolean = false;
  newsFeeds: Array<any> = [
  ];
  selectedNewsFeed: string = '';

  constructor(
    rest: OpenHabCacheService,
    thingService: ThingService,
    route: ActivatedRoute,
    zone: NgZone,
    viewRef: ChangeDetectorRef,
    private feedService: FeedService)
  {
    super(rest, thingService, route, zone, viewRef);
  }

  ngOnInit()
  {
    this.selectedThingTypes = [];
    super.ngOnInit();
    this.getNewsFeeds();
    this.loadingComplete = true;
  }

  // TODO: remove when dashboard updated
  activateThing(thing: Thing)
  {
    this.activeThing = thing;
    this.dialChannelPair = this.getDialChannelPair(thing);
  }

  getNewsFeeds() {
    this.feedService.getFeeds().subscribe(
      feeds => {
        if (!feeds || feeds.length === 0) {
          this.newsFeeds = [{'name': 'No feeds available.', 'url': 'http://a.b.c.d.e.f.g'}];
          this.selectedNewsFeed = this.newsFeeds[0].url;
          console.log(!feeds ? 'Feeds: null' : 'Feeds: []');
        } else {
          this.newsFeeds = feeds.feeds;
          this.selectedNewsFeed = this.newsFeeds[0].url;
          console.log('Feeds:', feeds);
        }
      },
      error => {
        this.newsFeeds = [{'name': 'Feed list unavailable.', 'url': 'http://a.b.c.d.e.f.g'}];
        this.selectedNewsFeed = this.newsFeeds[0].url;
        console.log('Feeds: Not available');
      }
    )
  }

  // TODO: remove when dashboard updated
  getDialChannelPair(thing: Thing): Pair<Channel>
  {
    const result: Pair<Channel> = null;
    if (!thing)
    {
      return result;
    }
    const thingType: string = this.thingService.getThingType(thing);
    // only HVAC have dials
    if (thingType !== 'hvac-thing')
    {
      return result;
    }
    // get low temp channel
    const lowTempChannel = thing.channels.find(c =>
      {
      return c.id === 'low-temperature';
    });
    // get high temp channel
    const highTempChannel = thing.channels.find(c =>
    {
      return c.id === 'high-temperature';
    });
    // combine and add to result
    return {
      first: lowTempChannel,
      second: highTempChannel
    };
  }

}
