import { Injectable } from '@angular/core';
import { Thing } from '../models/thing';
import { Light } from '../models/light';
import { LightTypes } from '../models/light-types';
import { UtilityService } from './utility.service';
import { Channel } from '../models/channel';
import { LightRates, RgbLightRates} from '../models/light-rates';
import { ThingService } from './thing.service';

@Injectable()
export class LightsService extends ThingService
{

  /**
   * Map of light channels to properties
   */
  channelMap: object =
  {
    dimmer: 'dimmer',
    mode:   'mode',
    switch: 'switch',
    time1:  'rateOn',
    time2:  'rateOff',
    sleep:  'sleep'
  };

  constructor(private utilityService: UtilityService)
  {
    super();
  }

  public CreateLight(thing: Thing): Light
  {
    const light: Light = new Light(thing);
    light.type = LightTypes['SWITCH'];
    if (!thing || !thing.channels || !thing.channels.length) { return light; }

    /** for dimmer configured as non-dimmable (for non-dimmable bulbs) */
    if (light.configuration.capability === 216)
    {
      light.type = LightTypes['DIMMERSWITCHED'];
    }

    this.updateFromChannels(light, light.channels);
    return light;
  }

  public updateFromChannels(light: Light, chans: Channel[])
  {
      chans.forEach(this.updateFromChannel.bind(this, light));
      this.updateRates(light);
  }

  public updateFromChannel(light: Light, chan: Channel)
  {
      if (!chan) { return; }
      const state: string = chan.item.state.toString();
      // get property on light corresponding to channel id
      const modelProperty = this.channelMap[chan.id];

      switch (true)
      {
        case chan.id === 'dimmer' && light.type !== LightTypes['DIMMERSWITCHED'] :
          light.type = LightTypes['DIMMABLE'];
          break;

        case chan.id === 'hsvcolor' :
          const hsvcolor: Array<any> = (state as string).split(',');
          light.type = LightTypes['RGB'];
          light.hue = <number>hsvcolor[0];
          light.saturation = <number>hsvcolor[1];
          light.luminosity = <number>hsvcolor[2];
          break;

        case chan.id === 'mode' :
          light.options = chan.item.stateDescription.options;
          break;

        case chan.id === 'speed' :
          const rateValue: number = Number(state);
          if (light.type === LightTypes['RGB'] && light.mode === 'BLINK')
          {
            light.rateOn = rateValue >> 8; // left 8 bits
            light.rateOff = rateValue & 255; // right 8 bits
          } else {
            light.rateOn = rateValue;
            light.rateOff = light.rateOn;
          }
          break;
      }

      if (modelProperty)
      {
        // if channel state parse-able to number
        if (!isNaN(Number(state)))
        {
          light[modelProperty] = Number(state);
        } else {
          light[modelProperty] = state;
        }
      }

  }

  public updateRates(light: Light)
  {
    // console.log('**updateRates()', light.rate, light.rateOn, light.rateOff);
      let lightRates: any = LightRates;
      // blink is split into on and off portion in one number
      // so must use 8-bit values for comparison
    if (light.type === LightTypes['RGB'] && light.mode === 'BLINK')
    {
        lightRates = RgbLightRates;
      }
      const checkRates: string[] = ['FAST', 'MEDIUM', 'SLOW'];

      let withinAnyRate: boolean = false;
      // check first two rates (no need to check 'SLOW',
      // there is no rate slower than it)
    for (let i = 0; i < (checkRates.length - 1); ++i)
    {
        // this rate and the next one (by name)
        const rateName: string = checkRates[i];
        const nextRateName: string = checkRates[i + 1];
        // actual rate objects to check
        const rate: any = lightRates[rateName];
        const nextRate: any = lightRates[nextRateName];
        // within any rate if within this rate
        // (since we break on true)
      withinAnyRate = this.utilityService.betweenInclusive(light.rateOn, rate.rateOff, nextRate.rateOff);
        // if within this rate, we are done
      if (withinAnyRate)
      {
          light.rate = rateName;
          break;
        }
      }
      // Occurs if rate not between fast/medium or medium/slow, or rate not within
      // any given rate
    if (!withinAnyRate)
    {
        light.rate = 'SLOW';
      }

    // console.log('**updateRates() after', light.rate, light.rateOn, light.rateOff);

  }

}
