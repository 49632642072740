import {
  Component,
  OnInit,
  AfterViewInit,
  Input
} from '@angular/core';

import { Channel } from '../models/channel';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSliderChange } from '@angular/material/slider';
import { ThingService } from '../services/thing.service';
import { OpenHabCacheService } from '../services/open-hab-cache.service';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit, AfterViewInit
{
  @Input() channel: Channel;

  constructor(protected rest: OpenHabCacheService, protected thingService: ThingService) { }

  ngOnInit()
  {
  }

  ngAfterViewInit()
  {
    console.log(new Date().toISOString() + ': view initialized');
  }

  channelSwitched(event: MatSlideToggleChange)
  {
    this.rest.sendCommand(this.channel.item.name, event.checked ? 'ON' : 'OFF').then(console.log);
  }

  sliderMoved(event: MatSliderChange)
  {
    console.log(new Date().toISOString() + ': slider moved');
    this.rest.sendCommand(this.channel.item.name, String(event.value)).then(console.log);
  }

  optionSelected(event: MatSelectChange)
  {
    console.log(new Date().toISOString() + ': option selected');
    this.rest.sendCommand(this.channel.item.name, String(event.value)).then(console.log);
  }

  inputChanged(event: Event)
  {
    const input: HTMLInputElement = <HTMLInputElement>event.target;
    this.rest.sendCommand(this.channel.item.name, String(input.value)).then(console.log);
  }

  getDisplayState()
  {
    return this.thingService.getDisplayState(this.channel);
  }

  getSuffix()
  {
    const hasPattern: boolean = Boolean(this.channel && this.channel.item && this.channel.item.stateDescription && this.channel.item.stateDescription.pattern);
    if (!hasPattern) { return; }
    const pattern: string = this.channel.item.stateDescription.pattern;
    // remove %x patterns and return string suffix only
    return pattern.replace(/%[a-zA-Z]/g, '');
  }

}
