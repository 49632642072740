import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Connection Error Dialog
 */
@Component({
    selector: 'app-dialog',
    template: `
            <h2 mat-dialog-title>{{title}}</h2>
            <mat-dialog-content>
                <p>{{description}}</p>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button *ngIf="retry" mat-button (click)="closeDialog()">{{retryText}}</button>
                <button *ngIf="reconnect" mat-button (click)="reconnectCommunity()">{{reconnectText}}</button>
            </mat-dialog-actions>
    `/* template */
  })
  export class DialogComponent implements OnInit {

    title: String;
    description: String;
    retry: Boolean;
    retryText: String;
    reconnect: Boolean;
    reconnectText: String;

    constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data['title'];
      this.description = data['description'];
      this.retry = data['retry'];
      this.retryText = data['retryText'];
      this.reconnect = data['reconnect'];
      this.reconnectText = data['reconnectText'];
    }
    ngOnInit() {
    }

    closeDialog(): void {
      this.dialogRef.close('RETRY');
    }
    reconnectCommunity(): void {
      top.location.href = 'https://onecontrolcloud.lci1.com/login';
    }
  }
