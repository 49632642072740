import { Component, OnInit, ElementRef, Input, ViewChild, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { Channel } from '../../models/channel';
import { Mouse } from '../../models/mouse';
import { OpenHabCacheService } from '../../services/open-hab-cache.service';


@Component({
  selector: 'app-dial',
  templateUrl: './dial.component.html',
  styleUrls: ['./dial.component.css']
})
export class DialComponent implements OnInit, OnChanges, AfterViewInit  {

    @Input() start: number;
    @Input() end: number;

    @Input() firstChannel: Channel;
    @Input() secondChannel: Channel;
    @Input() modeChannel: Channel;
    @Input() measure: string;

    activeSlider: Element;

  @ViewChild('root', { static: true, read: ElementRef }) el: ElementRef;
  @ViewChild('arc', { static: true, read: ElementRef }) arc: ElementRef;
  @ViewChild('circle', { static: true, read: ElementRef }) circle: ElementRef;

    strokeDashArray: string = '0, 0';

    bottomSliderPosition: { left: number, top: number };
    topSliderPosition: { left: number, top: number };

    bottomSliderPositionPixels: { left: string, top: string };
    topSliderPositionPixels: { left: string, top: string };

    svgTransform: any = {};

    readonly LOW_RANGE: number = 47;
    readonly HIGH_RANGE: number = 93;
    readonly SPACING: number = 2;

    constructor(private rest: OpenHabCacheService) {
      window.onresize = (e) => {
        this.setupDial();
      };
    }

    ngOnInit(): void {
      this.setupDial();
    }

    ngOnChanges(changes: SimpleChanges): void {
      this.setupDial();
    }

    ngAfterViewInit(): void {
      this.setupDial();
    }

    setupDial(): void {
      this.setSliders();
    }

    mouseDown(event: MouseEvent): void {
      Mouse.isDown = true;
    }

    mouseUp(event: MouseEvent): void {
      Mouse.isDown = false;
    }

    setActiveSlider(event: MouseEvent): void {
      event.preventDefault();
      // sliders crossing over each other, don't change active slider
      if (this.activeSlider) { return; }
      // moving over slider with mouse up, no active slider
      if (!Mouse.isDown) {
        this.activeSlider = null;
        return;
      }
      this.activeSlider = <Element>event.target;
    }

    moveActiveSlider(event: MouseEvent): void {
      event.preventDefault();
      if (!this.activeSlider) { return; }
      // moving over component with mouse up, no active slider
      const isTopSlider: boolean = this.activeSlider.className.indexOf('top') > -1;
      if (!Mouse.isDown) {
        // send command upon releasing widget handle
        // set both now as in cool only or heat only mode
        // both are moved
        this.rest.sendCommand(this.secondChannel.item.name, Number(this.end).toFixed(0)).then(console.log);
        this.rest.sendCommand(this.firstChannel.item.name, Number(this.start).toFixed(0)).then(console.log);
        this.activeSlider = null;
        return;
      }
      const circleCenter: any = this.getCircleCenter();
      const mouseFromCenter: any = {
        x: event.clientX - circleCenter.x,
        y: event.clientY - circleCenter.y
      };
      const radius: number = this.getRadius();
      const mouseLength: number = Math.sqrt(Math.pow(mouseFromCenter.x, 2) + Math.pow(mouseFromCenter.y, 2));
      const scaleFactor: number = radius / mouseLength;
      const scaledMouse: any = {
        left: mouseFromCenter.x * scaleFactor,
        top: mouseFromCenter.y * scaleFactor
      };
      const actualPosition: any = {
        left: scaledMouse.left + radius,
        top: scaledMouse.top + radius
      };

      const tooClose: boolean = Math.abs(this.end - this.start) < this.SPACING;

      // keep other slider within 2 degrees to prevent overlap
      if (tooClose) {
        if (isTopSlider) {
          this.start = this.end - this.SPACING;
        } else {
          this.end = this.start + this.SPACING;
        }
        this.setSliders();
      }

      const angle: number = this.getAngle(scaledMouse);
      if (!this.validateSliderPositions(angle)) {
        return;
      }

      if (isTopSlider) {
        this.setTopSliderPosition(actualPosition);
      } else {
        this.setBottomSliderPosition(actualPosition);
      }

      this.setArcAndRangeFromRotation();
    }

    setBottomSliderPosition(position): void {
      this.bottomSliderPosition = position;
      this.bottomSliderPositionPixels = {
        left: position.left + 'px',
        top: position.top + 'px'
      };
    }

    setTopSliderPosition(position): void {
      this.topSliderPosition = position;
      this.topSliderPositionPixels = {
        left: position.left + 'px',
        top: position.top + 'px'
      };
    }

    validateSliderPositions (newSliderAngle: number): boolean {
      // angle should never be out of unit circle
      if (newSliderAngle <= 0 || newSliderAngle >= 2 * Math.PI) {
        return false;
      }

      const isTopSlider: boolean = this.activeSlider.className.indexOf('top') > -1;
      const otherPosition: any = isTopSlider ? this.bottomSliderPosition : this.topSliderPosition;
      const radius: number = this.getRadius();
      const otherAngle: number = this.getAngle({
        left: otherPosition.left - radius,
        top: otherPosition.top - radius
      });

      if (isTopSlider) {
        // top slider should be greater than current bottom
        return newSliderAngle > otherAngle;
      }
      // bottom slider should be less than current top
      return newSliderAngle < otherAngle;
    }

    getCircleCenter(): any {
      const circle: Element = <Element>this.circle.nativeElement;
      const rect: ClientRect = circle.getBoundingClientRect();
      // TODO: handle scrolling
      // var radius = this.getRadius();
      return {
        x: rect.left + (rect.width / 2),
        y: rect.top + (rect.height / 2)
      };
    }

    lowValueValid(): boolean {
      return typeof this.start  === 'number' &&
        !isNaN(this.start) &&
        this.start < this.end &&
        this.start >= this.LOW_RANGE &&
        this.start !== 0;
    }

    highValueValid(): boolean {
      return typeof this.end  === 'number' &&
        !isNaN(this.end) &&
        this.end > this.start &&
        this.end <= this.HIGH_RANGE &&
        this.end !== 0;
    }

    getCurrentValues (): any {
      const start: number = this.lowValueValid() ? this.start : this.LOW_RANGE + 1;
      const end: number = this.highValueValid() ? this.end : this.HIGH_RANGE - 1;
      return {
        start: start,
        end: end
      };
    }

    setArcAndRangeFromRotation(): void {
      const radius: number = this.getRadius();
      const bottomAngle: number = this.getAngle({
        left: this.bottomSliderPosition.left - radius,
        top: this.bottomSliderPosition.top - radius
      });
      const topAngle: number = this.getAngle({
        left: this.topSliderPosition.left - radius,
        top: this.topSliderPosition.top - radius
      });
      this.redrawArc(bottomAngle, topAngle);
      this.updateRange(bottomAngle, topAngle);
    }

    updateRange (startAngle: number, endAngle: number): void {
      const rangeDiff: number = (this.HIGH_RANGE - this.LOW_RANGE);

      const startAngleTrue: number = Math.min(startAngle, endAngle);
      const endAngleTrue: number = Math.max(startAngle, endAngle);

      // algebraic inverse of value => angle function
      const startValue: number = (rangeDiff * startAngleTrue) / ( 2 * Math.PI) + this.LOW_RANGE;
      const endValue: number = (rangeDiff * endAngleTrue) / ( 2 * Math.PI) + this.LOW_RANGE;

      this.start = startValue;
      this.end = endValue;
    }

    getSliderPosition (el: Element): any {
      const offset: any = {
        top: el.clientTop,
        left: el.clientLeft
      };
      const circleCenter: any = this.getCircleCenter();
      return {
        x: offset.left - circleCenter.x,
        y: offset.top - circleCenter.y
      };
    }

    setSliders(): void {
      const currentValues: any = this.getCurrentValues();
      const rangeDiff: number = (this.HIGH_RANGE - this.LOW_RANGE);

      const bottomAngle: number = ((currentValues.start - this.LOW_RANGE) / rangeDiff) * 2 * Math.PI;
      const bottomXyPosition: any = this.getXyPosition(bottomAngle);

      const topAngle: number = ((currentValues.end - this.LOW_RANGE) / rangeDiff) * 2 * Math.PI;
      const topXyPosition: any = this.getXyPosition(topAngle);

      this.redrawArc(bottomAngle, topAngle);

      const radius: number = this.getRadius();

      this.setBottomSliderPosition({
        top: radius + bottomXyPosition.y,
        left: radius + bottomXyPosition.x
      });

      this.setTopSliderPosition({
        top: radius + topXyPosition.y,
        left: radius + topXyPosition.x
      });
    }

    redrawArc (startAngle, endAngle): void {
      let startAngleTrue: number = Math.min(startAngle, endAngle);
      let endAngleTrue: number = Math.max(startAngle, endAngle);

      // don't draw angle from disabled temperature value
      // (set to the same as the active temperature value)
      if (this.modeChannel && this.modeChannel.item && this.modeChannel.item.state) {
        const state: string = this.modeChannel.item.state.toString();
        if (state === 'COOL') {
          endAngleTrue = startAngleTrue;
        } else if (state === 'HEAT') {
          startAngleTrue = endAngleTrue;
        } else if (state === 'OFF') {
          startAngleTrue = endAngleTrue = 0;
        }
      }

      const circumference: number = 2 * Math.PI * this.getRadius();
      const startLength: number = circumference * (startAngleTrue / ( 2 * Math.PI));
      const endLength: number = circumference * (endAngleTrue / ( 2 * Math.PI));
      const length: number = endLength - startLength;
      const remainder: number = circumference - length;

      this.strokeDashArray = length + ', ' + remainder;

      const rotate: number = (startAngleTrue + (Math.PI / 2)) - 2 * Math.PI;

      const transformCss: string = 'rotate(' + rotate + 'rad)';

      this.svgTransform = {
        transform: transformCss,
        '-ms-transform': transformCss,
        '-webkit-transform': transformCss
      };
    }

    // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
    isChrome(): boolean {
      const isChromium = (<any>window).chrome;
      const winNav = window.navigator;
      const vendorName = winNav.vendor;
      const isOpera = typeof (<any>window).opr !== 'undefined';
      const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
      const isIOSChrome = winNav.userAgent.match('CriOS');

      if (isIOSChrome) {
        return true;
      }

      return isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false;
    }

    getStrokeDashArrayAttribute(): string{
      const c: number = 2 * Math.PI * this.getRadius();
      const range: number = this.HIGH_RANGE - this.LOW_RANGE;
      // pixels per temperature increment
      const incrementSize: number = c / range;
      return this.isChrome() ? '1px, calc(6.28318530717% - 1px)' : '1px, ' + incrementSize + 'px';
    }

    getRadiusAttribute(): string {
      return this.isChrome() ? 'calc(50% - 15px)' : this.getRadius().toString();
    }

    getMeasureRadiusAttribute(): string {
      return this.isChrome() ? 'calc(50% - 2.5px)' : ((this.circle.nativeElement.getBoundingClientRect().width / 2) + 10).toString();
    }

    // this radius is for finding the position of sliders
    getRadius (): number {
      // only chrome correctly gets bbox of svg circle
      // so use this in all other cases
      if (!this.isChrome()) {
        // average of width with border - width with border, divided by 2 for radius
        return (this.circle.nativeElement.getBoundingClientRect().width + this.circle.nativeElement.clientWidth) / 4;
      }
      // radius of the center of the border
      const el: SVGCircleElement = <SVGCircleElement>this.arc.nativeElement;
      return el.getBBox().width / 2;
    }

    // this radius is for calculations like angles
    getCalculationRadius(): number {
      return (<Element>this.circle.nativeElement).clientWidth / 2;
    }

    getXyUsingRadiusMethod(angle: number, method: Function): any {
      const radius: number = method.call(this);
      const x: number = radius *  Math.sin(angle);
      const y: number = radius *  Math.cos(angle);
      return {
        x: -x, // reflect about x axis (our 0 is at the bottom center)
        y: y
      };
    }

    // no idea why these methods need to work differently
    getXyPosition (angle): any {
      return this.getXyUsingRadiusMethod(angle, this.getRadius);
    }

    getXyPositionTest (angle): any {
      return this.getXyUsingRadiusMethod(angle, this.getCalculationRadius);
    }

    getAngle (xyPosition: any): number {
      const angle: number = Math.atan2(xyPosition.top, xyPosition.left);
      const angleAtBottom: number = angle + (3 * Math.PI / 2);
      const trueAngleAtBottom: number = angleAtBottom % (2 * Math.PI);
      return trueAngleAtBottom;
    }
  }
