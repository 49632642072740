import { ValueLabel } from './value-label';

export class StateDescription {
    minimum: number;
    maximum: number;
    step: number;
    pattern: string;
    readOnly: boolean;
    options: ValueLabel[];
}
