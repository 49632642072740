import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  constructor() { }

  public betweenExclusive(compare: number, low: number, high: number) {
    return low < compare && compare < high;
  }

  public betweenUpperInclusive(compare: number, low: number, high: number) {
    return low < compare && compare <= high;
  }

  public betweenLowerInclusive(compare: number, low: number, high: number) {
    return low <= compare && compare < high;
  }

  public betweenInclusive(compare: number, low: number, high: number) {
    return low <= compare && compare <= high;
  }

}
