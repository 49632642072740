import { Component, OnInit, Input, NgZone, ChangeDetectorRef } from '@angular/core';

import { Thing as Tank } from '../../../../models/thing';
import { Channel } from '../../../../models/channel';
import { DisplayChannels } from '../../../../models/display-channels.enum';
import { OpenHabService } from '../../../../services/open-hab.service';
import { ThingService } from '../../../../services/thing.service';
import { OpenHabEvent } from '../../../../models/open-hab-event';
import { ThingComponent } from '../../../../thing/thing.component';
import { OpenHabCacheService } from '../../../../services/open-hab-cache.service';

@Component({
  selector: 'app-tanks-widget',
  templateUrl: './tanks.widget.component.html',
  styleUrls: ['./tanks.widget.component.css']
})
export class TanksWidgetComponent extends ThingComponent implements OnInit {

  @Input() public tankInstance: Tank;
  tankDetailsChannel: Channel;


  constructor(rest: OpenHabCacheService, serv: ThingService, zone: NgZone, viewRef:  ChangeDetectorRef) {
    super(rest, serv, zone, viewRef);
  }

  ngOnInit() {
    console.log('ngOnInit > TanksWidgetComponent');
    this.tankInstance = this.thing;
    this.tankDetailsChannel = this.getChannelByProperty('id', 'tank-level');


    super.ngOnInit();

  }

}
